import React, {useEffect, useState} from 'react';
import './MobileModals.css';
import logoImageMobile from '../../assets/714-logo-vertical-mobile.jpg'

interface MobileLoadingProps {
    loading: boolean
}
const MobileLoading: React.FC<MobileLoadingProps> = ({loading}) => {
    const [zIndex, setZIndex] = useState(loading ? 10000000 : -1000000);

    useEffect(() => {
        if (loading) {
            setZIndex(10000)
        } else {
            const timer = setTimeout(() => setZIndex(-1000000), 500);
            return () => clearTimeout(timer);
        }
    }, [loading]);

    return (
        <div className='mobile-loading-container'
             style={{
                 opacity: loading? 100 : 0,
                 zIndex: zIndex,
                 transition: loading? "opacity 0ms" : "opacity 500ms ease-in-out",
             }}
        >
            <img className='mobile-loading-logo' src={logoImageMobile} alt="Loading..."/>
            <div className='mobile-loading-spinner'>
            </div>
        </div>
    )
}

export default MobileLoading;
