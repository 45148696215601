import './DateUtils.css';
import React from 'react';

const extractTimeZone = (dateString: string): string => {
    return dateString.slice(-6);
};

export const formatTimeZonedDateToSlash = (dateString: string): string => {
    const date = new Date(dateString);
    const timeZone = extractTimeZone(dateString);

    const month = new Intl.DateTimeFormat('default', { month: '2-digit', timeZone }).format(date);
    const day = new Intl.DateTimeFormat('default', { day: '2-digit', timeZone }).format(date);
    const year = String(date.getFullYear()).slice(-2);

    return `${month}/${day}/${year}`;
}

export const formatTimeZonedDateToMonth = (dateString: string): string => {
    const date = new Date(dateString);
    const timeZone = extractTimeZone(dateString);

    const monthAbbreviation = new Intl.DateTimeFormat('default', { month: 'short', timeZone }).format(date).toUpperCase();
    return monthAbbreviation;
}

export const formatTimeZonedDateToNumber = (dateString: string): string => {
    const date = new Date(dateString);
    const timeZone = extractTimeZone(dateString);

    const dateNumber = new Intl.DateTimeFormat('default', { day: 'numeric', timeZone }).format(date);

    return `${dateNumber}`
}

export const formatTimeZonedDateToWeekDayAndTime = (dateString: string | null): string => {
    if (!dateString) {
        return '';
    }
    const date = new Date(dateString);
    const timeZone = extractTimeZone(dateString);

    const weekday = new Intl.DateTimeFormat('default', { weekday: 'long', timeZone }).format(date);
    const time = new Intl.DateTimeFormat('default', { hour: 'numeric', minute: '2-digit', timeZone }).format(date);

    return `${weekday} ${time}`;
}

export const formatTimeZonedDateToWeekday = (dateString: string): string => {
    const date = new Date(dateString);
    const timeZone = extractTimeZone(dateString);

    const weekday = new Intl.DateTimeFormat('default', { weekday: 'short', timeZone }).format(date).toUpperCase();
    return weekday;
}

export const formatTimeZonedDateToMonthAndDay = (dateString: string): string => {
    const date = new Date(dateString);
    const timeZone = extractTimeZone(dateString);

    const month = new Intl.DateTimeFormat('default', { month: 'short', timeZone }).format(date);
    const dateNumber = new Intl.DateTimeFormat('default', { day: 'numeric', timeZone }).format(date);

    return `${month} ${dateNumber}`;
}

// export const formatDateObjToMonthAndDay = (date: Date): string => {
//     const timeZone = extractTimeZone(date.toISOString()); // Use ISO string for timezone extraction

//     const month = new Intl.DateTimeFormat('default', { month: 'short', timeZone }).format(date);
//     const dateNumber = new Intl.DateTimeFormat('default', { day: 'numeric', timeZone }).format(date);

//     return `${month} ${dateNumber}`;
// }

export const formatTimeZonedDateToTime = (dateString: string | null): string => {
    if (!dateString) {
        return '';
    }
    const date = new Date(dateString);
    const timeZone = extractTimeZone(dateString);

    const time = new Intl.DateTimeFormat('default', { hour: 'numeric', minute: '2-digit', timeZone }).format(date);
    return time;
}

export const formatTimeZonedDateToFullDate = (dateString: string | null): string => {
    if (!dateString) {
        return '';
    }
    const date = new Date(dateString);
    const timeZone = extractTimeZone(dateString);

    const weekday = new Intl.DateTimeFormat('default', { weekday: 'long', timeZone }).format(date);
    const month = new Intl.DateTimeFormat('default', { month: 'long', timeZone }).format(date);
    const day = new Intl.DateTimeFormat('default', { day: 'numeric', timeZone }).format(date);
    const year = date.getFullYear();
    const time = new Intl.DateTimeFormat('default', { hour: 'numeric', minute: '2-digit', timeZone }).format(date);

    return `${weekday}, ${month} ${day}, ${year} - ${time}`;
}

export const formatTimeZonedDateToYear = (dateString: string): string => {
    const date = new Date(dateString);
    const timeZone = extractTimeZone(dateString);

    const year = new Intl.DateTimeFormat('default', { year: 'numeric', timeZone }).format(date);
    return `${year}`;
}




export const formatDateToSlash = (dateString: string): string => {
    const date = new Date(dateString);

    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);

    return `${month}/${day}/${year}`;
}


export const formatDateToMonth = (dateString: string): string => {
    const date = new Date(dateString);
    const monthAbbreviation = date.toLocaleString('default', { month: 'short' }).toUpperCase();
    return monthAbbreviation;
}

export const formatDateToNumber = (dateString: string): number => {
    const date = new Date(dateString);
    const dateNumber = date.getDate();
    return dateNumber;
}

export const formatDateToWeekDayAndTime = (dateString: string): string => {
    const date = new Date(dateString);
    const weekday = date.toLocaleString('default', { weekday: 'long' });
    const time = date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
    return `${weekday} - ${time}`;
}

export const formatDateToWeekday = (dateString: string) => {
    const date = new Date(dateString);
    const weekday = date.toLocaleString('default', { weekday: 'short' }).toUpperCase();
    return weekday
}

export const formatDateToMonthAndDay = (dateString: string) => {
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'short' });
    const dateNumber = date.getDate()
    return `${month} ${dateNumber}`;
}

export const formatDateToTime = (dateString: string) => {
    const date = new Date(dateString);
    const time = date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
    return time;
}

export const formatDateToFullDate = (dateString: string) => {
    const date = new Date(dateString);
    const weekday = date.toLocaleString('default', { weekday: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate();
    const year = date.getFullYear();
    const time = date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });

    return `${weekday}, ${month} ${day}, ${year} - ${time}`;
}

export const formatDateToYear = (dateString: string): string => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    return `${year}`;
}


export const formatDateObjToMonthAndDay = (date: Date) => {
    const month = date.toLocaleString('default', { month: 'short' });
    const dateNumber = date.getDate()
    return `${month} ${dateNumber}`;
}

interface CalendarWidgetProps {
    dateTime: string | null;
}

export const CalendarWidgetSmall: React.FC<CalendarWidgetProps> = ({ dateTime }) => {
    if (!dateTime) {
        return null;
    }
    const currentYear = new Date().getFullYear();
    const currentYearString = `${currentYear}`;

    const dateYearString = formatDateToYear(dateTime)

    return (
        dateYearString > currentYearString ?
            <div className='date-block'>
                <div className='text-xxs'>{formatTimeZonedDateToMonth(dateTime)}</div>
                <div className='date-day-s'>{formatTimeZonedDateToNumber(dateTime)}</div>
                <div className='text-xxs'>{dateYearString}</div>
            </div>
            :
            <div className='date-block'>
                <div className='text-xs'>{formatTimeZonedDateToMonth(dateTime)}</div>
                <div className='date-day'>{formatTimeZonedDateToNumber(dateTime)}</div>
            </div>
    )
}


export const CalendarWidgetFull: React.FC<CalendarWidgetProps> = ({ dateTime }) => {
    if (!dateTime) {
        return null;
    }
    return (
        <div className='full-date-block'>
            <div className='text-s-light'>{formatTimeZonedDateToWeekday(dateTime)}</div>
            <div className='text-m'>{formatTimeZonedDateToMonthAndDay(dateTime)}</div>
            <div className='text-s-light'>{formatTimeZonedDateToTime(dateTime)}</div>
        </div>
    )
}



