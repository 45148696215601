import React from 'react';
import logo from '../../assets/714-logo-hover.svg';

interface ForgotPasswordResponseCardProps {
    success: boolean;
}

const ForgotPasswordResponseCard: React.FC<ForgotPasswordResponseCardProps> = ({success}) => {

    return (
        <div className='card'>
            <div className='text-l'>{success ? "Password Reset Requested!" : "Issue Requesting Password Reset"}</div>
            <div className='card-item'>
                <div className='text-s-light'>
                    {success ? "Please check your email for a link to reset your password": "Please try again..."}
                </div>
            </div>
            <br/>
            <img src={logo} alt="714 Logo" style={{ width: '140px', height: 'auto', marginBottom: "4px" }} />
        </div>
    )
}

export default ForgotPasswordResponseCard;
