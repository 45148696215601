import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Navbar from '../components/Navbar/Navbar';
import ConsignmentFormCard from '../components/Consignment/ConsignmentFormCard';
import Footer from '../components/Footer/Footer';
import {canBeParsedToInt} from '../utils/GlobalUtils';
import Event from '../interfaces/Event';
import {useAuthenticationContext} from '../providers/AuthenticationProvider';
import LogInFormCard from '../components/LogIn/LogInFormCard';
import MarkdownCard from '../components/MarkdownCard/MarkdownCard';
import {isRight} from 'fp-ts/lib/Either';
import {fetchEventById} from '../api/endpoints';
import ListingSource from '../interfaces/ListingSource';
import {useScreenSizeContext} from "../providers/ScreenSizeProvider";
import SignUpFormCard from "../components/SignUp/SignUpFormCard";


const ConsignmentPage: React.FC = () => {
    const { eventName, isCustom = '', eventId = '' } = useParams<{ eventName: string, isCustom: string, eventId: string }>();
    const [event, setEvent] = useState<Event | null>(null)
    const [loadingEvent, setLoadingEvent] = useState<boolean>(false);
    const [loginOrSignup, setLoginOrSignup] = useState<boolean>(true);

    const { isLoggedIn, isAuthLoading} = useAuthenticationContext();
    const navigate = useNavigate();
    const location = useLocation();
    const { isMobile } = useScreenSizeContext();

    useEffect(() => {

        const fetchEvent = async () => {
            setLoadingEvent(true);
            const eventDataEither = await fetchEventById(parseInt(eventId), ListingSource.TEVO);
            if (isRight(eventDataEither)) {
                setEvent(eventDataEither.right);
            } else {
                navigate(`/not-found`);
            }
            setLoadingEvent(false);
        }

        if (canBeParsedToInt(eventId)) {
            fetchEvent();
        } else if (eventId !== '') {
            navigate(`/not-found`);
        }
    }, []);

    useEffect(() => {
        if (isAuthLoading) {
            navigate(location.pathname.replace('/login', '') + '/loading',  { replace: true })
            return
        }
        if (!isLoggedIn && !location.pathname.includes('/login')) {
            navigate((location.pathname + '/login').replace('/loading', ''),  { replace: true })
        }
        if (isLoggedIn) {
            navigate((location.pathname).replace('/login', '').replace('/loading', ''),  { replace: true })
        }

    }, [isLoggedIn, isAuthLoading])

    return (
        <div>
            <Navbar showSearch={true} alwaysSolid={true} />
            <div className='container-info'>
                <div className='content-form' style={isMobile ? {margin: '25px 0px'} : {}}>
                    {isAuthLoading || loadingEvent? <div className="loading-spinner"></div>
                        :
                        !isLoggedIn ?
                            <div>
                                {loginOrSignup ?
                                    <LogInFormCard onClickSignUp={() => setLoginOrSignup(false)} />
                                    :
                                    <SignUpFormCard onClickLogIn={() => setLoginOrSignup(true)}/>
                                    }
                                {isMobile && <><br/>
                                    <div className='divider-margin'/>
                                    <br/></>}
                                <MarkdownCard filePath="/markdown/consignment.md" />
                            </div>
                            :
                            <>
                                <ConsignmentFormCard event={event}/>
                                {isMobile && <><br/>
                                    <div className='divider-margin'/>
                                    <br/></>}
                                <MarkdownCard filePath="/markdown/consignment.md" />
                            </>
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ConsignmentPage;
