import apiClientAuth from './apiClientAuth'
import {useAuthenticationContext} from '../providers/AuthenticationProvider';
import {MutableRefObject, useEffect, useRef} from 'react'


interface AuthenticationProviderProps {
    children: React.ReactNode;
}

const AxiosAuthenticationInterceptor: React.FC<AuthenticationProviderProps> = ({children}) => {
    const {accessToken, isAuthLoading} = useAuthenticationContext();
    const requestQueue = useRef<Array<(token: string | null) => void>>([]);
    const actualInterceptor: MutableRefObject<any> = useRef(null);

    useEffect(() => {
        apiClientAuth.interceptors.request.use((config) => {
            if (actualInterceptor.current !== null) {
                return actualInterceptor.current(config);
            }
            return config;
        })
    }, []);

    useEffect(() => {
        if (!isAuthLoading && requestQueue.current.length > 0) {
            requestQueue.current.forEach(fn => fn(accessToken));
            requestQueue.current = [];
        }
        actualInterceptor.current = (config: any) => {
            if (isAuthLoading) {
                return new Promise((resolve) => {
                    requestQueue.current.push((token) => {
                        if (token) config.headers.Authorization = `Bearer ${token}`;
                        resolve(config);
                    });
                });
            }

            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
            return config;
        };
    }, [isAuthLoading, accessToken]);

    return <>{children}</>;
}

export default AxiosAuthenticationInterceptor;
