import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import './Navbar.css';
import {Link} from "react-router-dom";
import {FaBars, FaChevronDown, FaTimes, FaUser} from 'react-icons/fa';
import {useAuthenticationContext} from '../../providers/AuthenticationProvider';
import SearchBarNav from '../SearchBar/SearchBarNav';
import {getPerformerUrlParams} from '../../utils/GlobalUtils';
import DropdownMenu from '../../interfaces/DropdownMenu';
import DropdownSubMenu from '../../interfaces/DropdownSubMenu';
import {useGlobalContext} from '../../providers/GlobalProvider';
import {LoggedInMenu, LoginMenu} from '../LogIn/LogInMenus';
import {useScreenSizeContext} from '../../providers/ScreenSizeProvider';
import SearchBar from '../SearchBar/SearchBar';


interface NavbarDropdownProps {
    menu: DropdownMenu;
}

const NavbarDropdown: React.FC<NavbarDropdownProps> = ({ menu }) => {

    const [selectedSubMenu, setSelectedSubMenu] = useState(menu.categories[0]);

    const handleCategoryHover = (submenu: DropdownSubMenu) => {
        setSelectedSubMenu(submenu);
    }

    return (
        <div className="dropdown-menu">
            <div className='row align-start'>
                <div className='col align-start'>
                    {menu.categories.map(submenu => (
                        <div className="dropdown-item" onMouseEnter={() => handleCategoryHover(submenu)}>
                            {submenu.name}
                        </div>
                    ))}
                </div>
                <div className='gap-s'></div>
                <div className='col justify-start align-start dropdown-scroll'>
                    {selectedSubMenu.performers.map(performer => (
                        <Link
                            key={performer.tevoPerformerId}
                            to={`/performer/${getPerformerUrlParams(performer)}`}
                            className="dropdown-item"
                        >
                            {performer.name}
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};



interface NavbarRef {
    triggerMobileDropdownSearch: () => void;
}

interface NavbarProps {
    showSearch?: boolean;
    alwaysSolid?: boolean;
    fullScreen?: boolean;
}

const Navbar = forwardRef<NavbarRef, NavbarProps>(
    ({ showSearch = false, alwaysSolid = false, fullScreen = false }, ref) => {
    const { isLoggedIn } = useAuthenticationContext();
    const { dropdown } = useGlobalContext();
    const { isMobile, isMapMobile } = useScreenSizeContext();

    const loginRef = useRef<HTMLDivElement>(null);
    const searchBarRef = useRef<{triggerSearchBarFocus: () => void}>(null);

    const [solidHeader, setSolidHeader] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState('');
    const [activeLogin, setActiveLogin] = useState(false);
    const [isActiveMobileMenu, setIsActiveMobileMenu] = useState(false);
    const [selectedMobileMenu, setSelectedMobileMenu] = useState(-1);
    const [selectedMobileSubMenu, setSelectedMobileSubMenu] = useState(-1);


    useImperativeHandle(ref, () => ({
        triggerMobileDropdownSearch: () => {

            setIsActiveMobileMenu(true);
            setTimeout(() => {
                if (searchBarRef.current) {
                    searchBarRef.current.triggerSearchBarFocus();
                }
            }, 200);
        },
    }));


    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition < 30) {
                setSolidHeader(false);
            } else {
                setSolidHeader(true);
            }
        };

        const handleClickOutsideLogin = (e: MouseEvent) => {
            if (loginRef.current && e.target instanceof Node && !loginRef.current.contains(e.target)) {
                setActiveLogin(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        document.addEventListener('mousedown', handleClickOutsideLogin);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            document.removeEventListener('mousedown', handleClickOutsideLogin);
        };
    }, []);

    const renderDropdown = () => {
        if (dropdown == null) {
            return null;
        } else {
            return dropdown.map(menu => (
                <div key={menu.name} className='navbar-item' onMouseEnter={() => setActiveDropdown(menu.name)} onMouseLeave={() => setActiveDropdown('')}>
                    {menu.name}
                    {activeDropdown === menu.name && <NavbarDropdown menu={menu} />}
                </div>
            ));
        }
    };

    const handleSelectedMobileMenu = (menuIndex: number) => {
        if (selectedMobileMenu == menuIndex) {
            setSelectedMobileMenu(-1)
            setSelectedMobileSubMenu(-1)
        } else {
            setSelectedMobileMenu(menuIndex)
            setSelectedMobileSubMenu(-1)
        }
    }

    const handleSelectedMobileSubMenu = (subMenuIndex: number) => {
        if (selectedMobileSubMenu == subMenuIndex) {
            setSelectedMobileSubMenu(-1)
        } else {
            setSelectedMobileSubMenu(subMenuIndex)
        }
    }

    const clearMobileMenu = () => {
        setIsActiveMobileMenu(false)
        setSelectedMobileMenu(-1)
        setSelectedMobileSubMenu(-1)
    }

    const refreshPage = () => {
        if (window.location.pathname === '/consignment') {
            window.location.href = '/consignment';
        }
    };


    return (
        <>
            <div style={{
                top: 0,
                left: 0,
                position: 'fixed',
                right: 0,
                height: isActiveMobileMenu ? '100vh' : '0vh',
                zIndex: 99999,
                transition: "300ms ease-in-out",
                overflow: "scroll",
                backgroundColor: 'black'
            }}>
                <Link to="/" className='absolute navbar-logo' style={{
                    top: 8, left: 20,
                }} onClick={clearMobileMenu}></Link>
                <div className='absolute' style={{ top: 20, right: 22 }} onClick={() => setIsActiveMobileMenu(false)}>
                    <FaTimes className='navbar-icon-l' onClick={() => setIsActiveMobileMenu(false)} style={{
                        transform: (isActiveMobileMenu) ? 'rotate(0deg)' : 'rotate(-45deg)',
                        opacity: (isActiveMobileMenu) ? 1 : 0,
                        transition: '300ms ease-in-out'
                    }} />
                </div>

                <div className='col align-start' style={{ paddingTop: 80, paddingLeft: 50, paddingRight: 50 }}>
                    <div className='col' style={{
                        bottom: 100,
                        opacity: (isActiveMobileMenu) ? 1 : 0,
                        transition: '600ms ease-in-out',
                        left: 0,
                        right: 0,
                        margin: 'auto',
                        width: '100%'
                    }}>
                        <SearchBar ref={searchBarRef} dropdown={true}/>
                        <br />
                    </div>

                    {
                        dropdown.map((menu, menuIndex) => (
                            <>
                                <div className='row' onClick={() => handleSelectedMobileMenu(menuIndex)}>
                                    <div className='left'>
                                        <div className='mobile-menu-text'>
                                            {menu.name.toUpperCase()}
                                            <br />
                                        </div>
                                    </div>
                                    <FaChevronDown className='navbar-icon-l' style={{
                                        transform: (selectedMobileMenu == menuIndex) ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: '200ms ease-in-out'
                                    }} />
                                </div>
                                {selectedMobileMenu == menuIndex &&

                                    menu.categories.map((category, categoryIndex) => (
                                        <>
                                            <div className='row' style={{ paddingLeft: 25, paddingRight: 5, boxSizing: 'border-box' }} onClick={() => handleSelectedMobileSubMenu(categoryIndex)}>
                                                <div className='left'>
                                                    <div className='mobile-submenu-text'>
                                                        {category.name}
                                                    </div>
                                                </div>
                                                <FaChevronDown className='navbar-icon-s' style={{
                                                    transform: (selectedMobileSubMenu == categoryIndex) ? 'rotate(180deg)' : 'rotate(0deg)',
                                                    transition: '200ms ease-in-out'
                                                }} />
                                            </div>
                                            {selectedMobileSubMenu == categoryIndex &&
                                                category.performers.map(performer => (

                                                    <Link
                                                        key={performer.tevoPerformerId}
                                                        to={`/performer/${getPerformerUrlParams(performer)}`}
                                                        className="mobile-performer-text"
                                                        style={{ paddingLeft: 50 }}
                                                        onClick={clearMobileMenu}
                                                    >
                                                        {performer.name}
                                                    </Link>
                                                ))
                                            }

                                        </>
                                    ))

                                }

                            </>
                        ))
                    }

                    <div className='col' style={{
                        bottom: 100,
                        opacity: (isActiveMobileMenu) ? 1 : 0,
                        transition: '600ms ease-in-out',
                        left: 0,
                        right: 0,
                        margin: 'auto',
                        width: '100%'
                    }}>
                        <Link className="mobile-menu-button" to={isLoggedIn ? "/consignment" : "/consignment/login"}  onClick={refreshPage}>
                            Sell Tickets
                        </Link>
                    </div>

                </div>


            </div>
            <div className={`navbar-container ${(alwaysSolid || solidHeader) && 'navbar-solid'}`}>
                <div className={`content-main ${fullScreen && 'full-width'}`}>
                    <div className='row'>
                        <div className='left'>
                            <Link to="/" className='navbar-logo'></Link>
                            {!isMobile && (showSearch ?
                                <>
                                    <SearchBarNav />
                                    <div className='gap-m' />
                                    <Link className="navbar-button" to={isLoggedIn ? "/consignment" : "/consignment/login"}  onClick={refreshPage}>
                                        {isMapMobile ? "Sell" : "Sell Tickets"}
                                    </Link>
                                </>
                                :
                                <>
                                    {renderDropdown()}
                                    <div className='gap-m' />
                                    <Link className="navbar-button" to={isLoggedIn ? "/consignment" : "/consignment/login"} onClick={refreshPage}>
                                        {isMapMobile ? "Sell" : "Sell Tickets"}
                                    </Link>
                                </>
                            )
                            }

                        </div>
                        {isMobile ?
                            <>
                                <Link to={isLoggedIn ? "/account" : "/account/login"}>
                                    <FaUser className='navbar-icon' onClick={() => setActiveLogin(!activeLogin)} />
                                </Link>
                                <div className='gap-m' />
                                <FaBars className='navbar-icon' onClick={() => setIsActiveMobileMenu(true)} />
                            </>
                            :
                            <>
                                <div ref={loginRef} className='relative'>
                                    <div className='row navbar-item' style={{ padding: "0px 20px" }} onClick={() => setActiveLogin(!activeLogin)}>
                                        <FaUser />
                                        <div className='gap-s' />

                                        {isLoggedIn ? (isMapMobile ? "Account" : "My Account") : "Log In"}
                                    </div>
                                    {activeLogin &&
                                        (isLoggedIn ?
                                            <LoggedInMenu />
                                            :
                                            <LoginMenu />
                                        )
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
})

export default Navbar;
