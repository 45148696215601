import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom";
import HomePage from "./pages/HomePage";
import PerformerPage from "./pages/PerformerPage";
import VenuePage from "./pages/VenuePage";
import EventPage from "./pages/EventPage";
import SignupPage from './pages/SignupPage';
import ConsignmentPage from './pages/ConsignmentPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import VerificationPage from './pages/VerificationPage';
import CheckoutPage from './pages/CheckoutPage';
import AccountPage from './pages/AccountPage';
import {AuthenticationProvider} from './providers/AuthenticationProvider';
import AxiosAuthenticationInterceptor from './api/AxiosAuthenticationInterceptor';
import ReadBlobProvider from './providers/ReadBlobProvider';
import './App.css';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import RewardsPage from './pages/RewardsPage';
import AboutUsPage from './pages/AboutUsPage';
import NotFoundPage from './pages/NotFoundPage';
import GlobalProvider from './providers/GlobalProvider';
import AdminPage from './pages/AdminPage';
import WriteBlobProvider from './providers/WriteBlobProvider';
import LoginPage from './pages/LoginPage';
import SearchResultsPage from './pages/SearchResultsPage';
import ScreenSizeProvider from './providers/ScreenSizeProvider';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';



const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App: React.FC = () => {

  useEffect(() => {
    ReactGA.initialize('G-RFXY8K9QR1');
  }, []);

  return (
    <ScreenSizeProvider>
      <AuthenticationProvider>
        <AxiosAuthenticationInterceptor>
          <GlobalProvider>
            <ReadBlobProvider>
                <Router>
                  <ScrollToTop />
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/performer/:performerName/:performerId" element={<PerformerPage />} />
                    <Route path="/venue/:venueName/:venueId" element={<VenuePage />} />
                    <Route path="/search/:encodedQuery" element={<SearchResultsPage />} />
                    <Route path="/event/:eventName/:isCustom/:eventId" element={<EventPage />} />
                    <Route path="/signup" element={<SignupPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/reset-password/:resetToken" element={<ResetPasswordPage />} />
                    <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                    <Route path="/consignment/:eventName?/:isCustom?/:eventId?/:loggedIn?" element={<ConsignmentPage />} />
                    <Route path="/verification/:verificationToken" element={<VerificationPage />} />
                    <Route path="/checkout/:isCustom/:eventId/:selectedQuantity/:listingId/:loggedIn?" element={<CheckoutPage />} />
                    <Route path="/account/:activeTab?/:loggedIn?" element={<AccountPage />} />
                    <Route path="/admin/:activeTab?" element={<AdminPage />} />
                    <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                    <Route path="/rewards" element={<RewardsPage />} />
                    <Route path="/about-us" element={<AboutUsPage />} />
                    <Route path="/not.-found" element={<NotFoundPage />} />
                    <Route path="*" element={<NotFoundPage />} />
                  </Routes>
                </Router>
            </ReadBlobProvider>
          </GlobalProvider>
        </AxiosAuthenticationInterceptor>
      </AuthenticationProvider>
    </ScreenSizeProvider>
  );
}

export default App;
