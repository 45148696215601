import React from 'react';
import logo from '../../assets/714-logo-hover.svg';
import {useScreenSizeContext} from "../../providers/ScreenSizeProvider";


interface ConsignmentResponseCardProps {
    success: boolean;
    submittedConsignments: number[];
}

const ConsignmentResponseCard: React.FC<ConsignmentResponseCardProps> = ({success, submittedConsignments}) => {
    const { isMobile } = useScreenSizeContext();
    return (
        <div className={!isMobile ? 'card' : ''}>
            <div className='text-l'>{success ? "Consignment Successfully Submitted!" : "Issue Proccessing Consignment"}</div>
            <div className='card-item'>
                <div className='text-s-light'>
                    {success ? "Please check your account page for details or updates on the following consignments:\n#" + submittedConsignments.join(", #") : "Please try again..."}
                </div>
            </div>
            <br/>
            <img src={logo} alt="714 Logo" style={{ width: '140px', height: 'auto', marginBottom: "4px" }} />
        </div>
    )
}

export default ConsignmentResponseCard;
