import React from 'react';
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';
import Verification from '../components/Verification/Verification';


const VerificationPage: React.FC = () => {
    return (
        <div>
            <Navbar showSearch={true} alwaysSolid={true}/>
            <Verification/>
            <Footer/>
        </div>
    )
}

export default VerificationPage;
