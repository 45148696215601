import React from 'react';
import ResetPasswordFormCard from './ResetPasswordFormCard';

const ResetPassword: React.FC = () => {
    return (
        <div className='container-info'>
            <div className='content-form'>
                <ResetPasswordFormCard/>
            </div>
        </div>
    )
}

export default ResetPassword;
