import React, {useCallback, useEffect, useRef, useState} from 'react';
import categoryMap from '../../assets/nested_category_map.json'
import {useReadBlobContext} from '../../providers/ReadBlobProvider';
import {useWriteBlobContext} from '../../providers/WriteBlobProvider';
import Performer from '../../interfaces/Performer';
import {fetchPerformersByQuery} from '../../api/endpoints';
import Modal from 'react-modal';
import {isRight} from "fp-ts/Either";


const AdminPerformerBanners: React.FC = () => {
    const searchRef = useRef<HTMLDivElement>(null);
    const lastSearchedRef = useRef('');
    const { imageReadBlobService } = useReadBlobContext();
    const {imageWriteBlobService} = useWriteBlobContext();
    const [isFocused, setIsFocused] = useState(false);
    const [performerQuery, setPerformerQuery] = useState('');
    const [loadingPerformers, setLoadingPerformers] = useState(false);
    const [performerResults, setPerformerResults] = useState<Performer[] | null>(null);
    const [imagesToUpload, setImagesToUpload] = useState<Map<string, File>>(new Map());
    const [expandedNodes, setExpandedNodes] = useState<Map<string, boolean>>(new Map());

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [modalPath, setModalPath] = useState<string | null>(null);

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDialog = (path: string) => {
        setOpenDialog(true);
        setModalPath(path);
    };

    const [performers, setPerformers] = useState<string[]>([]);

    useEffect(() => {
        const getPerformers = async () => {
            if (imageReadBlobService) {
                setPerformers((await imageReadBlobService.getAllPathsFromBase('performers/specific/')).map(path => (path.split('/')[2])));
            }
        }
        getPerformers();
    }, [])

    const handleCategoryFileChange = (path: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if (files && files.length > 0) {
            setImagesToUpload(prevMap => {
                const newMap = new Map(prevMap);
                newMap.set(path, files[0]);
                return newMap;
            });
        }
    };

    const toggleNodeExpansion = (path: string) => {
        setExpandedNodes(prevMap => {
            const newMap = new Map(prevMap);
            newMap.set(path, !newMap.get(path));
            return newMap;
        });
    };

    interface TreeNodeProps {
        node: Record<string, any>;
        name: string;
        path: string;
    }

    const TreeNode: React.FC<TreeNodeProps> = ({ node, name, path }) => {
        const isExpanded = expandedNodes.get(path) || false;
        const imageUrl = imageReadBlobService?.getExactUrlForPath("performers/" + path + "/img.jpg");

        return (
            <div >
                <div className='card-item'>
                <div className='card-list'>
                <div className='row'>
                    <div className='left'>
                        <div className='text-xs gap-xl'>{name}</div>
                        <input type="file" className='no-display' id={`file-input-${path}`} onChange={handleCategoryFileChange(path)} />
                        <label htmlFor={`file-input-${path}`} className='admin-button'>Upload Image</label>
                        {(imagesToUpload.has(path) || imageUrl != null) &&
                            <>
                                {imagesToUpload.has(path) ?
                                    <button className='admin-button' onClick={() => handleOpenDialog(path)}>View New Image</button>
                                    :
                                    <button className='admin-button' onClick={() => handleOpenDialog(imageUrl as string)}>View Existing Image</button>
                                }
                            </>
                        }
                    </div>
                    {Object.keys(node).length > 0 && (
                        <button className='admin-button' onClick={() => toggleNodeExpansion(path)}>
                            {isExpanded ? 'Collapse' : 'Expand'}
                        </button>
                    )}
                </div>
                {isExpanded && (
                    <div>
                        {Object.entries(node).map(([key, value]) => (
                            <div style={{ marginLeft: '20px' }}>
                            <TreeNode key={key} node={value} name={key} path={`${path}/${key}`} />
                            </div>
                        ))}
                    </div>
                )}
                </div>
            </div>
            </div>
        );
    };

    const handlePerformerQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPerformerQuery(e.target.value);
    };


    const fetchPerformerResults = async (query: string) => {
        if (query === '') {
            setPerformerResults(null);
            return;
        }
        setLoadingPerformers(true);
        try {
            lastSearchedRef.current = query;

            const resultResponseEither = await fetchPerformersByQuery(query, false);
            if (isRight(resultResponseEither)) {
                setPerformerResults(resultResponseEither.right);
            } else {
                console.error(resultResponseEither.left)
            }

        } catch (error) {
            console.error('Error fetching performers:', error);
        }
        setLoadingPerformers(false);
    }


    const debounceFetchResults = useCallback(
        (() => {
          let timeoutId: NodeJS.Timeout;
          return (searchQuery: string) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                fetchPerformerResults(searchQuery);
            }, 300);
          };
        })(),
        []
    );


    const handleFocus = () => {
        setIsFocused(true);
    };


    useEffect(() => {
        debounceFetchResults(performerQuery);
    }, [performerQuery, debounceFetchResults]);


    const handleSelectedPerformer = (performer: Performer) => {
        setIsFocused(false);

        const updatedPerformers = [...performers];
        updatedPerformers.push(performer.slug);
        setPerformers(updatedPerformers);
    }


    const handleSubmitChanges = async () => {
        if (imageWriteBlobService) {
            for (const [path, imageFile] of imagesToUpload) {
                imageWriteBlobService.uploadFile("performers/" + path + "/img.jpg", imageFile, 1920)
            }
        }
    }

    return (
        <div className='card card-top'>
             <div className='row'>
                <div className='left'>
                    <div className='text-l'>Performer Banners</div>
                </div>
                <button className='admin-action-button' onClick={handleSubmitChanges}>Save Changes</button>
            </div>
            <div className='card-item'>
                <div className='text-xs-bold'>
                    By Category
                </div>
            </div>
            <div className='card-item'>
                <div>
                    {Object.entries(categoryMap).map(([key, value]) => (
                        <TreeNode key={key} node={value} name={key} path={key} />
                    ))}
                </div>
            </div>
            <div className='card-item'>
                <div className='text-xs-bold'>
                    By Performer
                </div>
            </div>
            <div ref={searchRef}>
                <div className='card-item'>
                    <input
                        type="text"
                        value={performerQuery}
                        onFocus={handleFocus}
                        onChange={handlePerformerQueryChange}
                        placeholder="Search for the name of the performer or team to add"
                        className='admin-search'
                    />
                </div>
                {(performerResults !== null && isFocused) &&
                    <div className='relative'>
                        <div className='input-search-results'>
                            {performerResults.map(performer => (
                                <div className="menu-item" onClick={() => handleSelectedPerformer(performer)}>
                                    <div className='menu-item-title'>{performer.name}</div>
                                </div>))}
                        </div>
                    </div>
                }
                <div className='card-item'>
                    {performers.map(performer => {
                        const path = "specific/" + performer;
                        const imageUrl = imageReadBlobService?.getExactUrlForPath("performers/" + path + "/img.jpg");
                        return (
                            <div className='card-item'>
                            <div className='card-list'>
                                <div className='row'>
                                    <div className='text-xs gap-xl'>{performer}</div>
                                    <input type="file" className='no-display' id={`file-input-${path}`} onChange={handleCategoryFileChange(path)} />
                                    <label htmlFor={`file-input-${path}`} className='admin-button'>Upload Image</label>
                                    {(imagesToUpload.has(path) || imageUrl != null) &&
                                        <>
                                            {imagesToUpload.has(path) ?
                                                <button className='admin-button' onClick={() => handleOpenDialog(path)}>View New Image</button>
                                                :
                                                <button className='admin-button' onClick={() => handleOpenDialog(imageUrl as string)}>View Existing Image</button>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        )
                    })}
                </div>
            </div>
            <Modal isOpen={openDialog} onRequestClose={handleCloseDialog} style={{
                content: {
                    display: 'flex',
                    flexDirection: 'column',
                    width: 'auto',
                    maxWidth: '1000px',
                    margin: 'auto',
                    top: '125px',
                    bottom: '125px',
                    padding: '40px',
                    borderRadius: '20px'
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                }}}
                appElement={document.getElementById('root') || undefined}
            >
                {modalPath && (imagesToUpload.has(modalPath)) ?
                    <img
                        src={URL.createObjectURL(imagesToUpload.get(modalPath) as File)}
                        alt="Uploaded"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                    />
                    :
                    (
                        modalPath &&
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: `url(${modalPath})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat'
                            }}
                        />
                )
                }
            </Modal>
        </div>
    );
}

export default AdminPerformerBanners;
