import './DropdownUtils.css';
import DatePicker from 'react-datepicker';
import Slider from '@mui/material/Slider';
import React, {useState} from 'react';
import {useScreenSizeContext} from "../../providers/ScreenSizeProvider";
import {FaTimes} from "react-icons/fa";


interface DateRangeMenuProps {
    startDate: Date | null;
    endDate: Date | null;
    onChange: (dates: [Date | null, Date | null]) => void;
    onClose: () => void;
}

export const DateRangeMenu: React.FC<DateRangeMenuProps> = ({ startDate, endDate, onChange, onClose}) => {
    const { isMobile } = useScreenSizeContext();

    return (
        <div className={`${isMobile ? "mobile-filter-menu" : "dropdown right-aligned"}`}>
            {isMobile &&
                <div className='row' style={{justifyContent: "center"}}>
                    <button className='filter-button' style={{position: "absolute", top: "27px", left: "30px"}}
                        onClick={() => onChange([null, null])}
                    >
                        Clear
                    </button>
                    <div className='text-s' style={{margin: "10px 0"}}>
                        Date Range
                    </div>
                    <div style={{position: "absolute", top: "32px", right: "30px", fontSize: "24px", cursor: "pointer"}}
                         onClick={onClose}
                    >
                        <FaTimes/>

                    </div>
                </div>
            }
            <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
                calendarClassName={`datepicker ${isMobile ? 'mobile-calendar' : ''}`}
            />
        </div>
    );
};


interface MultiSelectMenuProps {
    options: string[],
    selectedOptions: Set<string>;
    setSelectedOptions: React.Dispatch<React.SetStateAction<Set<string>>>;
    onClose: () => void;
}

export const MultiSelectMenu: React.FC<MultiSelectMenuProps> = ({options, selectedOptions, setSelectedOptions, onClose}) => {

    const { isMobile } = useScreenSizeContext();

    const handleCheckboxChange = (option: string) => {

        setSelectedOptions(prevSelectedOptions => {
            const newSelectedOptions = new Set(prevSelectedOptions);
            if (newSelectedOptions.has(option)) {
                newSelectedOptions.delete(option);
            } else {
                newSelectedOptions.add(option);
            }
            return newSelectedOptions;
        })
    }

    return (
        <div className={`${isMobile ? "mobile-filter-menu" : "dropdown-menu right-aligned"}`}>
            {isMobile &&
                <>
                    <div className='row' style={{justifyContent: "center"}}>
                        <button className='filter-button' style={{position: "absolute", top: "27px", left: "30px"}}
                                onClick={() => setSelectedOptions(new Set())}
                        >
                            Clear
                        </button>
                        <div className='text-s' style={{margin: "10px 0"}}>
                            Select Option(s)
                        </div>
                        <div style={{
                            position: "absolute",
                            top: "32px",
                            right: "30px",
                            fontSize: "24px",
                            cursor: "pointer"
                        }}
                            onClick={onClose}
                        >
                            <FaTimes/>

                        </div>
                    </div>
                    <br/>
                </>
            }
            <div style={isMobile ? {display: 'flex', flexDirection: 'column', overflowY: 'scroll', overflowX: 'hidden', maxHeight: '70vh', width: '100%', scrollbarWidth: "none"} : {}}>
                {options.map(option => (
                        <div key={option} style={isMobile ? {width: '95%', justifyContent: 'left', margin: "5px 0px",} : {}} className="select-item" onClick={() => handleCheckboxChange(option)}>
                            <input
                                type="checkbox"
                                id={option}
                                checked={selectedOptions.has(option)}
                                onChange={() => {
                                }}
                                className='checkbox'
                            />
                            {option}
                        </div>
                    )
                )}
            </div>
        </div>
    );
};


interface SingleSelectMenuProps {
    options: string[],
    selectedOption: string;
    setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
    closeMenu: () => void;
    mustSelect: boolean;
    rightAligned?: boolean
}

export const SingleSelectMenu: React.FC<SingleSelectMenuProps> = ({ options, selectedOption, setSelectedOption, closeMenu, mustSelect, rightAligned = false }) => {

    const { isMobile } = useScreenSizeContext();


    const handleSelect = (option: string) => {
        closeMenu();
        setSelectedOption(prevSelectedOption => {
            if (!mustSelect && prevSelectedOption == option) {
                return ''
            } else {
                return option
            }
        })
    }

    return (
        <div className={`${isMobile ? "mobile-filter-menu" : "dropdown-menu" + (rightAligned ? ' right-aligned' : '')}`}>
            {isMobile &&
                <>
                <div className='row' style={{justifyContent: "center"}}>
                    {!mustSelect &&
                        <button className='filter-button' style={{position: "absolute", top: "27px", left: "30px"}}
                            onClick={() => setSelectedOption("")}
                        >
                        Clear
                    </button>}
                    <div className='text-s' style={{margin: "10px 0"}}>
                        Select Option
                    </div>
                    <div style={{position: "absolute", top: "32px", right: "30px", fontSize: "24px", cursor: "pointer"}}
                         onClick={closeMenu}
                    >
                        <FaTimes/>

                    </div>
                </div>
                <br/>
                </>
            }
            {options.map(option => (
                <div key={option} style={isMobile ? {width: '95%', justifyContent: 'center', margin: "5px 0px"} : {}} className={`select-item ${option == selectedOption && "selected-item"}`}
                     onClick={() => handleSelect(option)}>
                    {option}
                </div>
            )
            )}

        </div>
    );
};


interface SliderMenuProps {
    rangeOptions: [number, number],
    selectedOptions: [number, number]
    setSelectedOptions: React.Dispatch<React.SetStateAction<[number, number]>>;
    onClose: () => void;
}

export const SliderMenu: React.FC<SliderMenuProps> = ({ rangeOptions, selectedOptions, setSelectedOptions, onClose }) => {
    const { isMobile } = useScreenSizeContext();

    const [localSelectedOptions, setLocalSelectedOptions] = useState<[number, number]>(selectedOptions);

    const handleChange = (event: globalThis.Event, newValue: number | number[]) => {
        if (Array.isArray(newValue)) {
            setLocalSelectedOptions([newValue[0], newValue[1]]);
        }
    };

    const handleChangeCommitted = (event: React.SyntheticEvent | globalThis.Event, newValue: number | number[]) => {
        if (Array.isArray(newValue)) {
            setSelectedOptions([newValue[0], newValue[1]]);
        }
    };

    const handleInputChange = (index: number, value: string) => {
        if (value == '') {
            setLocalSelectedOptions(prevOptions => {
                const newOptions: [number, number] = [...prevOptions];
                newOptions[index] = 0;
                return newOptions;
            });
            setSelectedOptions(prevOptions => {
                const newOptions: [number, number] = [...prevOptions];
                newOptions[index] = 0;
                return newOptions;
            });
        }

        let newValue = parseInt(value);
        if (!isNaN(newValue)) {
            setLocalSelectedOptions(prevOptions => {
                const newOptions: [number, number] = [...prevOptions];
                newOptions[index] = newValue;
                return newOptions;
            });
            setSelectedOptions(prevOptions => {
                const newOptions: [number, number] = [...prevOptions];
                newOptions[index] = newValue;
                return newOptions;
            });
        }
    };

    return (
        <div className={`${isMobile ? "mobile-filter-menu" : "dropdown-menu"}`}>
            {isMobile &&
                <>
                    <div className='row' style={{justifyContent: "center"}}>
                        <button className='filter-button' style={{position: "absolute", top: "27px", left: "30px"}}
                                onClick={() => {
                                    setSelectedOptions(rangeOptions)
                                    setLocalSelectedOptions(rangeOptions)
                                }}
                        >
                            Clear
                        </button>
                        <div className='text-s' style={{margin: "10px 0"}}>
                            Select Range
                        </div>
                        <div style={{position: "absolute", top: "32px", right: "30px", fontSize: "24px", cursor: "pointer"}}
                             onClick={onClose}
                        >
                            <FaTimes/>

                        </div>
                    </div>
                    <br/>
                </>
            }
            <div className='row space-around'>
                <div className='text-xs-bold'>Min:</div>
                <div className='text-xs-bold'>Max:</div>

            </div>
            <div className='row'>
                {/* <div className='slider-value'>${localSelectedOptions[0]}</div>
                <div className='slider-value'>${localSelectedOptions[1]}</div> */}

                <input
                    type="text"
                    className='slider-value'
                    value={localSelectedOptions[0] > 0 ? `$${new Intl.NumberFormat().format(localSelectedOptions[0])}` : ''}
                    onChange={(e) => handleInputChange(0, e.target.value.replace('$', ''))}
                />
                <input
                    type="text"
                    className='slider-value'
                    value={localSelectedOptions[1] > 0 ? `$${new Intl.NumberFormat().format(localSelectedOptions[1])}` : ''}
                    onChange={(e) => handleInputChange(1, e.target.value.replace('$', ''))}
                />
            </div>
            <div className='row' style={{padding: "0px 10px", boxSizing: 'border-box'}}>
                <Slider
                    value={localSelectedOptions}
                    min={rangeOptions[0]}
                    max={rangeOptions[1]}
                    onChange={handleChange}
                    onChangeCommitted={handleChangeCommitted}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => `$${new Intl.NumberFormat().format(value)}`}
                />
            </div>
        </div>
    );
};
