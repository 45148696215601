import React, {useEffect, useRef, useState} from 'react';
import {DataGrid, GridRowParams} from '@mui/x-data-grid';
import {
    addCustomListing,
    addListingCSV,
    fetchAllCustomEvents,
    fetchAllCustomListings,
    makeCustomEvent,
    removeCustomEvent,
    removeCustomListing
} from '../../api/endpoints';
import {isRight} from 'fp-ts/lib/Either';

import Modal from 'react-modal';
import {SingleSelectMenu} from '../../utils/DropwdownUtils/DropdownUtils';
import DeliveryMethod from '../../interfaces/DeliveryMethod';
import CustomEventDto from '../../interfaces/CustomEventDto';
import {useWriteBlobContext} from '../../providers/WriteBlobProvider';
import {useReadBlobContext} from '../../providers/ReadBlobProvider';
import Listing from '../../interfaces/Listing';
import ListingType from '../../interfaces/ListingType';
import ListingSource from '../../interfaces/ListingSource';


const AdminCustomEvents: React.FC = () => {
    const { imageWriteBlobService } = useWriteBlobContext();
    const { imageReadBlobService } = useReadBlobContext();

    const deliveryRef = useRef<HTMLDivElement>(null);

    const [loadingCustomEvents, setLoadingCustomEvents] = useState(false);
    const [loadingListings, setLoadingListings] = useState(false);
    const [customEvents, setCustomEvents] = useState<CustomEventDto[]>([]);
    const [customEventsToDelete, setCustomEventsToDelete] = useState<CustomEventDto[]>([]);
    const [selectedCustomEvent, setSelectedCustomEvent] = useState(-1);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [openImageDialog, setOpenImageDialog] = useState<boolean>(false);
    const [activeDeliveryPicker, setActiveDeliveryPicker] = useState(false);
    const [listings, setListings] = useState<Listing[]>([]);
    const [selectedListing, setSelectedListing] = useState<Listing | null>(null);
    const [selectedEventId, setSelectedEventId] = useState<number | null>(null);
    const [loadedImages, setLoadedImages] = useState<Map<String, String | null>>(new Map());
    const [imagesToUpload, setImagesToUpload] = useState<Map<String, File>>(new Map());
    const [newSplits, setNewSplits] = useState('');
    const [modalImageProduction, setModalImageProduction] = useState<string | null>(null);

    const listingColumns = [
        { field: 'listingId', headerName: 'ID', width: 100 },
        { field: 'section', headerName: 'Section', width: 100 },
        { field: 'row', headerName: 'Row', width: 100 },
        { field: 'qty', headerName: 'Quantity', width: 100 },
        { field: 'splits', headerName: 'Splits', width: 100 },
        { field: 'price', headerName: 'Price', width: 100 },
        { field: 'type', headerName: 'Type', width: 150 },
        { field: 'shippingMethod', headerName: 'Method', width: 150 },
    ];

    const handleRowClick = (eventId: number) => (params: GridRowParams) => {
        const newSelectedListing = listings.filter(listing => listing.listingId == params.row.listingId)[0]
        setSelectedListing(newSelectedListing)
        setNewSplits(newSelectedListing.splits.join(" "))
        setOpenDialog(true);
        setSelectedEventId(eventId);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleCloseImageDialog = () => {
        setOpenImageDialog(false);
    };

    const handleOpenImageDialog = (customEventImageUUID: string) => {
        setOpenImageDialog(true);
        setModalImageProduction(customEventImageUUID);
    };

    const handleNewSplitsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!selectedListing) {
            return;
        }
        try {
            const newSplits: number[] = e.target.value.trim().split(" ").map(item => parseInt(item)).filter(item => !Number.isNaN(item))
            const updatedSelectedListing = { ...selectedListing, splits: newSplits };
            setSelectedListing(updatedSelectedListing);
            setNewSplits(e.target.value);
        } catch {
        }

        setNewSplits(e.target.value);
    };

    const handleListingTypeChange = () => {
        if (!selectedListing) {
            return;
        }
        if (selectedListing.type == ListingType.PARKING) {
            const updatedSelectedListing = { ...selectedListing, type: ListingType.EVENT };
            setSelectedListing(updatedSelectedListing);
        } else {
            const updatedSelectedListing = { ...selectedListing, type: ListingType.PARKING };
            setSelectedListing(updatedSelectedListing);
        }
    }

    const fetchCustomEvents = async () => {
        setLoadingCustomEvents(true);
        const customEventsDataResponseEither = await fetchAllCustomEvents();
        if (isRight(customEventsDataResponseEither)) {
            const customEventsData = customEventsDataResponseEither.right;
            const updatedCustomEventsData = customEventsData.map((customEvent) => ({
                ...customEvent,
                eventTime: customEvent.eventTime ? customEvent.eventTime.slice(0, -6) : null,
            }));
            setCustomEvents(updatedCustomEventsData);

            const customEventImageUrlMap = new Map();
            for (let i = 0; i < customEventsData.length; i++) {
                const customEventImageUUID = customEventsData[i].mapImageLocation
                const imagePath = imageReadBlobService?.getUrlIfPathExists("maps/" + customEventImageUUID + "/img.jpg")
                customEventImageUrlMap.set(customEventImageUUID, imagePath);
            }
            setLoadedImages(customEventImageUrlMap);
            setSelectedEventId(null)
        } else {
            console.error(customEventsDataResponseEither.left)
        }

        setLoadingCustomEvents(false);
    };

    useEffect(() => {
        const handleClickOutsideDeliveryPicker = (e: MouseEvent) => {
            if (deliveryRef.current && e.target instanceof Node && !deliveryRef.current.contains(e.target)) {
                setActiveDeliveryPicker(false);
            }
        };

        fetchCustomEvents();
        document.addEventListener('mousedown', handleClickOutsideDeliveryPicker);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideDeliveryPicker);
        };
    }, []);

    const addListing = (eventId: number) => {
        const newListing: Listing = { section: '', row: '', qty: 0, price: 0, tevoSectionName: '', brokerId: 0, publicNotes: '', splits: [], type: ListingType.EVENT, shippingMethod: DeliveryMethod.MOBILE_DELIVERY, listingSource: ListingSource.CUSTOM };
        setSelectedListing(newListing);
        setSelectedEventId(eventId);
        setOpenDialog(true);
    };

    const addCustomEvent = () => {
        const newEvent: CustomEventDto = { eventName: '', venueName: '', venueLocation: '', eventTime: formatLocalDateTime(new Date()), mapImageLocation: '', enabled: true };
        setSelectedCustomEvent(customEvents.length);
        setCustomEvents([...customEvents, newEvent]);
    };

    const handleCustomEventNameChange = (eventIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedEvents = [...customEvents];
        updatedEvents[eventIndex].eventName = e.target.value;
        setCustomEvents(updatedEvents);
    };

    const handleCustomEventVenueNameChange = (eventIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedEvents = [...customEvents];
        updatedEvents[eventIndex].venueName = e.target.value;
        setCustomEvents(updatedEvents);
    };

    const handleCustomEventTimeChange = (eventIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedEvents = [...customEvents];
        updatedEvents[eventIndex].eventTime = e.target.value;
        setCustomEvents(updatedEvents);
    };

    const handleCustomEventHasDateChange = (eventIndex: number) => {
        const updatedEvents = [...customEvents];
        if (updatedEvents[eventIndex].eventTime == null) {
            updatedEvents[eventIndex].eventTime = formatLocalDateTime(new Date())
        } else {
            updatedEvents[eventIndex].eventTime = null;
        }
        setCustomEvents(updatedEvents);
    };

    const handleCustomEventVenueLocationChange = (eventIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedEvents = [...customEvents];
        updatedEvents[eventIndex].venueLocation = e.target.value;
        setCustomEvents(updatedEvents);
    };

    const handleCustomEventEnabledChange = (eventIndex: number, enabled: boolean) => {
        const updatedEvents = [...customEvents];
        updatedEvents[eventIndex].enabled = enabled;
        setCustomEvents(updatedEvents);
    }

    const deleteCustomEvent = (eventIndex: number) => {
        setCustomEventsToDelete([...customEventsToDelete, customEvents[eventIndex]])

        const updatedEvents = customEvents.filter((_, index) => index !== eventIndex);
        setCustomEvents(updatedEvents);
    };

    const formatLocalDateTime = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };


    const handleListingChangeSubmit = async () => {
        if (!selectedListing || !selectedEventId) {
            return;
        }
        const addCustomListingResponseEither = await addCustomListing({ listing: selectedListing, eventId: selectedEventId });
        if (isRight(addCustomListingResponseEither)) {
            setListings(addCustomListingResponseEither.right)
        } else {
            console.error(addCustomListingResponseEither.left)
        }
        handleCloseDialog();
    }

    const handleListingDelete = async () => {
        if (!selectedListing || !selectedEventId || !selectedListing.listingId) {
            return;
        }
        const addCustomListingResponseEither = await removeCustomListing({ listingId: selectedListing.listingId, eventId: selectedEventId });
        if (isRight(addCustomListingResponseEither)) {
            setListings(addCustomListingResponseEither.right)
            handleCloseDialog()
        } else {
            console.error(addCustomListingResponseEither.left)
        }
        handleCloseDialog();
    }

    const handleSubmitChanges = async () => {
        setLoadingCustomEvents(true);
        if (imageWriteBlobService) {
            for (const [customEventImageUUID, imageFile] of imagesToUpload) {
                imageWriteBlobService.uploadFile("maps/" + customEventImageUUID + "/img.jpg", imageFile)
            }
        }

        for (let i = 0; i < customEventsToDelete.length; i++) {
            if (customEventsToDelete[i].id !== undefined) {
                const deleteCustomEventResponseEither = await removeCustomEvent(customEventsToDelete[i].id as number);
                if (isRight(deleteCustomEventResponseEither)) {
                } else {
                    console.error(deleteCustomEventResponseEither.left)
                }
            }
        }

        for (let i = 0; i < customEvents.length; i++) {
            const customEvent = customEvents[i]
            if (customEvent.eventName == '' || customEvent.mapImageLocation == '') {
                continue
            }

            const setCustomEventResponseEither = await makeCustomEvent({ ...customEvent, eventTime: customEvent.eventTime ? `${customEvent.eventTime}-00:00` : null });
            if (isRight(setCustomEventResponseEither)) {
            } else {
                console.error(setCustomEventResponseEither.left)
            }
        }

        fetchCustomEvents();
    }


    const setSelectedListingShippingMethod = (selectedOption: string) => {
        if (!selectedListing) {
            return;
        }
        if (selectedListing.shippingMethod !== selectedOption) {
            const updatedSelectedListing = { ...selectedListing, shippingMethod: selectedOption as DeliveryMethod };
            setSelectedListing(updatedSelectedListing);
        }
    };


    const createSelectedListingShippingChangeMethod: React.Dispatch<React.SetStateAction<string>> = (newSelectedOption: string | ((prevState: string) => string)) => {
        if (typeof newSelectedOption === 'function') {
            newSelectedOption = newSelectedOption(selectedListing ? selectedListing.shippingMethod : "");
        }
        setSelectedListingShippingMethod(newSelectedOption);
    };

    const handleCustomEventSelect = async (eventIndex: number) => {

        if (selectedCustomEvent === eventIndex) {
            setSelectedCustomEvent(-1)
        } else {
            setSelectedCustomEvent(eventIndex);
        }

        const eventId = customEvents[eventIndex].id
        if (eventId) {
            setLoadingListings(true);
            const customListingsData = await fetchAllCustomListings(eventId);
            if (isRight(customListingsData)) {
                setListings(customListingsData.right);
            } else {
                console.error(customListingsData.left)
            }
            setLoadingListings(false);
        }
    }

    const handleListingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setSelectedListing((prevListing) => {
            if (!prevListing) return prevListing;
            return {
                ...prevListing,
                [name]: name === 'price' || name === 'qty' ? parseFloat(value) || '' : value
            };
        });
    };

    const handleCustomEventFileChange = (eventIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const customEventImageUUID = crypto.randomUUID();
        const files = e.target.files;

        if (files && files.length > 0) {
            setImagesToUpload(prevMap => {
                const newMap = new Map(prevMap);
                newMap.set(customEventImageUUID, files[0]);
                return newMap;
            });

            const updatedEvents = [...customEvents];
            updatedEvents[eventIndex].mapImageLocation = customEventImageUUID;
            setCustomEvents(updatedEvents);
        }
    };

    const handleCustomEventCSVChange = (eventId: number) => async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        const file = (files && files.length > 0) ? files[0] : null;
        if (file) {
            const addListingCSVResponseEither = await addListingCSV(file, eventId);
            if (isRight(addListingCSVResponseEither)) {
                setListings(addListingCSVResponseEither.right)
            } else {
                console.error(addListingCSVResponseEither.left)
            }
        }
    };

    return (
        <div className='card card-top' >
            <div className='row'>
                <div className='left'>
                    <div className='text-l'>Custom Productions</div>
                </div>
                <button className='admin-action-button' onClick={handleSubmitChanges}>Save Changes</button>
            </div>
            {loadingCustomEvents ?
                <div className="loading-spinner" />
                :
                <>
                    <div className='card-item'>
                        <button className='admin-button' onClick={addCustomEvent}>Add Custom Production</button>
                    </div>
                    <div className='card-item'>
                        {customEvents.map((event, eventIndex) => {
                            return (
                                <div className='card-item'>
                                    <div className='card-list gray-bg'>
                                        <div className='row'>
                                            <div className='left'>
                                                <div className='text-xs'>Production Name:&nbsp;</div>
                                                <input type="text" className='admin-input' value={event.eventName} onChange={handleCustomEventNameChange(eventIndex)} placeholder="Type Name..." />
                                                <div className='gap-s' />
                                                {event.eventTime &&
                                                    <input
                                                        type="datetime-local"
                                                        value={event.eventTime}
                                                        onChange={handleCustomEventTimeChange(eventIndex)}
                                                        className='admin-input'
                                                    />
                                                }

                                                <div className='gap-s' />
                                                <div className='text-xs'>Has Date: </div>
                                                <input type="checkbox" checked={event.eventTime != null} onChange={() => handleCustomEventHasDateChange(eventIndex)} className='admin-checkbox' />

                                                <div className='gap-s' />
                                                <div className='text-xs'>Enabled: </div>
                                                <input type="checkbox" checked={event.enabled} onChange={() => handleCustomEventEnabledChange(eventIndex, !event.enabled)} className='admin-checkbox' />
                                                <div className='gap-s' />

                                            </div>

                                            <button className='admin-button' onClick={() => handleCustomEventSelect(eventIndex)}>{selectedCustomEvent === eventIndex ? "Collapse" : "Expand"}</button>
                                            <button className='admin-delete-button' onClick={() => deleteCustomEvent(eventIndex)}>Delete</button>

                                        </div>
                                        {selectedCustomEvent === eventIndex &&
                                            <div className='card-item'>
                                                <div className='card-item'>
                                                    <div className='row'>
                                                        <div className='text-xs gap-xl'>Venue Name:&nbsp;</div>
                                                        <input type="text" className='admin-input' style={{ width: '300px' }} value={event.venueName} onChange={handleCustomEventVenueNameChange(eventIndex)} placeholder="Type Venue Name..." />

                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='text-xs gap-xl'>Venue Location:&nbsp;</div>
                                                    <input type="text" className='admin-input' style={{ width: '300px' }} value={event.venueLocation} onChange={handleCustomEventVenueLocationChange(eventIndex)} placeholder="Type Venue Location..." />

                                                </div>
                                                <div className='card-item'>
                                                    <div className='row'>
                                                        <div className='text-xs gap-xl'>Production Image:&nbsp;</div>
                                                        <input type="file" className='no-display' id={`file-input-${eventIndex}`} onChange={handleCustomEventFileChange(eventIndex)} />
                                                        <label htmlFor={`file-input-${eventIndex}`} className='admin-button'>Upload Image</label>
                                                        {(imagesToUpload.has(event.mapImageLocation) || loadedImages.get(event.mapImageLocation) != null) &&
                                                            <>
                                                                {imagesToUpload.has(event.mapImageLocation) ?
                                                                    <button className='admin-button' onClick={() => handleOpenImageDialog(event.mapImageLocation)}>View New Image</button>
                                                                    :
                                                                    <button className='admin-button' onClick={() => handleOpenImageDialog(event.mapImageLocation)}>View Existing Image</button>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                {event.id ?
                                                    <>
                                                        <div className='row'>
                                                            <div className='text-xs gap-xl'>Upload Listings:&nbsp;</div>
                                                            <button className='admin-button' onClick={() => addListing(event.id as number)}>Single Listing</button>
                                                            <input type="file" className='no-display' id={`csv-input-${eventIndex}`} onChange={handleCustomEventCSVChange(event.id)} />
                                                            <label htmlFor={`csv-input-${eventIndex}`} className='admin-action-button'>Bulk CSV</label>

                                                        </div>
                                                        <div className='card-item'>
                                                            {loadingListings ?
                                                                <div className="loading-spinner" />
                                                                :
                                                                <DataGrid
                                                                    rows={listings}
                                                                    columns={listingColumns}
                                                                    getRowId={(row) => `${row.listingId}`}
                                                                    pageSizeOptions={[5, 10, 20]}
                                                                    className='admin-data-grid'
                                                                    onRowClick={handleRowClick(event.id as number)}
                                                                />
                                                            }

                                                        </div>
                                                    </>
                                                    :
                                                    <div className='row'>
                                                        <div className='text-xs gap-xl'>Upload Listings:&nbsp;</div>
                                                        <div className='text-xs'>Please save changes to add listings</div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </>
            }

            <Modal isOpen={openDialog} onRequestClose={handleCloseDialog} style={{
                content: {
                    display: 'flex',
                    flexDirection: 'column',
                    width: '500px',
                    height: '500px',
                    margin: 'auto',
                    top: '125px',
                    bottom: '125px',
                    padding: '40px',
                    borderRadius: '20px',
                    zIndex: '1000000',
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                }
            }}
                appElement={document.getElementById('root') || undefined}
            >
                <div>
                    {selectedListing &&
                        <>
                            <div className='card-item'>
                                {selectedListing.listingId ?
                                    <div className='text-m'>{"Listing Details (#" + selectedListing.listingId + "): "}</div>
                                    :
                                    <div className='text-m'>{"Create New Listing: "}</div>
                                }
                            </div>

                            <hr />

                            <div className='row'>
                                <div className='text-xs-bold gap-xl'>Shipping Method:&nbsp;</div>
                                <div className='relative' ref={deliveryRef}>
                                    <button className={`admin-button ${'admin-filtered'}`} onClick={(() => setActiveDeliveryPicker(!activeDeliveryPicker))}>{selectedListing ? selectedListing.shippingMethod : "Delivery Method"}</button>
                                    {activeDeliveryPicker &&
                                        <SingleSelectMenu
                                            options={Object.values(DeliveryMethod)}
                                            selectedOption={selectedListing.shippingMethod}
                                            setSelectedOption={createSelectedListingShippingChangeMethod}
                                            closeMenu={() => setActiveDeliveryPicker(false)}
                                            mustSelect={true}
                                            rightAligned={false}
                                        />
                                    }
                                </div>
                            </div>
                            <div className='row'>
                                <div className='text-xs-bold gap-xl'>Section:&nbsp;</div>
                                <input
                                    type="text"
                                    className='admin-input'
                                    name="section"
                                    value={selectedListing.section}
                                    onChange={handleListingChange}
                                    placeholder="Type Section..."
                                />
                            </div>
                            <div className='row'>
                                <div className='text-xs-bold gap-xl'>Row:&nbsp;</div>
                                <input type="text" className='admin-input' name="row" value={selectedListing.row} onChange={handleListingChange} placeholder="Type Row..." />
                            </div>
                            <div className='row'>
                                <div className='text-xs-bold gap-xl'>Quantity:&nbsp;</div>
                                <input type="text" className='admin-input' name="qty" value={selectedListing.qty} onChange={handleListingChange} placeholder="Type Quantity..." />
                            </div>
                            <div className='row'>
                                <div className='text-xs-bold gap-xl'>Price:&nbsp;</div>
                                <input type="number" className='admin-input' name="price" value={selectedListing.price} onChange={handleListingChange} placeholder="Type Price..." />
                            </div>
                            <div className='row'>
                                <div className='text-xs-bold gap-xl'>Broker Id:&nbsp;</div>
                                <input type="text" className='admin-input' name="brokerId" value={selectedListing.brokerId} onChange={handleListingChange} placeholder="Type Broker Id..." />
                            </div>
                            <div className='row'>
                                <div className='text-xs-bold gap-xl'>Public Notes:&nbsp;</div>
                                <input type="text" className='admin-input' name="publicNotes" value={selectedListing.publicNotes} onChange={handleListingChange} placeholder="Type Public Notes..." />
                            </div>
                            <div className='row'>
                                <div className='text-xs-bold gap-xl'>Splits:&nbsp;</div>
                                <input type="text" className='admin-input' name="splits" value={newSplits} onChange={handleNewSplitsChange} placeholder="Type Splits... (ex: 2, 4)" />
                            </div>
                            <div className='card-item'>
                                <div className='row'>
                                    <div className='text-xs-bold gap-xl'>Type:&nbsp;</div>
                                    <div className='text-xs'>Parking: </div>
                                    <input type="checkbox" checked={selectedListing.type == ListingType.PARKING} onChange={handleListingTypeChange} className='admin-checkbox' />
                                </div>
                            </div>

                            <hr />

                            <div className='card-item'>
                                <div className='row'>
                                    <button className='admin-action-button' onClick={handleListingChangeSubmit}>Save Changes</button>
                                    <div className='gap-s' />
                                    {selectedListing.listingId &&
                                        <button className='admin-delete-button' onClick={handleListingDelete}>Delete Listing</button>
                                    }
                                </div>
                            </div>

                        </>
                    }

                </div>

            </Modal>
            <Modal isOpen={openImageDialog} onRequestClose={handleCloseImageDialog} style={{
                content: {
                    display: 'flex',
                    flexDirection: 'column',
                    width: 'auto',
                    maxWidth: '1000px',
                    margin: 'auto',
                    top: '125px',
                    bottom: '125px',
                    padding: '40px',
                    borderRadius: '20px'
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                }
            }}
                appElement={document.getElementById('root') || undefined}
            >
                {modalImageProduction && (imagesToUpload.has(modalImageProduction) ?
                    <img
                        src={URL.createObjectURL(imagesToUpload.get(modalImageProduction) as File)}
                        alt="Uploaded"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                    />
                    :
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            backgroundImage: `url(${loadedImages.get(modalImageProduction)})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                        }}
                    />
                )}
            </Modal>
        </div>
    )
}

export default AdminCustomEvents;
