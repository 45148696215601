import React, {useEffect, useRef, useState} from 'react';
import ResponseMessage from '../../interfaces/ResponseMessage';
import {useGlobalContext,} from '../../providers/GlobalProvider';
import {SingleSelectMenu} from '../../utils/DropwdownUtils/DropdownUtils';
import ShippingPricing from '../../interfaces/ShippingPricing';
import {useScreenSizeContext} from '../../providers/ScreenSizeProvider';
import {FaInfoCircle, FaTimes} from 'react-icons/fa';
import {Tooltip} from 'react-tooltip';

interface PaymentFormCardProps {
    firstName: string;
    lastName: string;
    cardNumber: string;
    month: string;
    year: string;
    CVV: string;
    rewardsAvailable: number,
    costBeforeRewards: number,
    couponApplied: boolean,
    rewardsApplied: boolean
    couponMessage: ResponseMessage | null,
    rewardsMessage: ResponseMessage | null,
    expiryMessage: ResponseMessage | null,
    orderNotes: string;
    upgrade: boolean;
    hasShippingMethod: boolean;
    setFirstName: React.Dispatch<React.SetStateAction<string>>;
    setLastName: React.Dispatch<React.SetStateAction<string>>;
    setCardNumber: React.Dispatch<React.SetStateAction<string>>;
    setMonth: React.Dispatch<React.SetStateAction<string>>;
    setYear: React.Dispatch<React.SetStateAction<string>>;
    setCVV: React.Dispatch<React.SetStateAction<string>>
    setShipmentId: React.Dispatch<React.SetStateAction<ShippingPricing | null>>;
    setUpgrade: React.Dispatch<React.SetStateAction<boolean>>
    handleApplyCoupon: (coupon: string) => Promise<void>
    handleApplyRewards: (rewards: number) => void;
    handleClearCoupon: () => void;
    handleClearRewards: () => void;
    setOrderNotes: React.Dispatch<React.SetStateAction<string>>
    chargesComponent: React.ReactNode;
}

const PaymentFormCard: React.FC<PaymentFormCardProps> = ({ firstName, lastName, cardNumber, month, year, CVV, rewardsAvailable, costBeforeRewards, couponApplied, rewardsApplied, couponMessage, rewardsMessage, expiryMessage, orderNotes, upgrade, hasShippingMethod, setFirstName, setLastName, setCardNumber, setMonth, setYear, setCVV, setShipmentId, setUpgrade, handleApplyCoupon, handleApplyRewards, handleClearCoupon, handleClearRewards, setOrderNotes, chargesComponent}) => {
    const [couponText, setCouponText] = useState('');
    const [rewardsText, setRewardsText] = useState('');
    const [lastAppliedRewards, setLastAppliedRewards] = useState(0);
    const { shippingMethods } = useGlobalContext();
    const [activeShippingPicker, setActiveShippingPicker] = useState(false);
    const [selectedShippingMethod, setSelectedShippingMethod] = useState(shippingMethods.length > 0 ? shippingMethods[0].name + " - $" + shippingMethods[0].cost.toFixed(2) : '');

    const shippingRef = useRef<HTMLDivElement>(null);
    const { isMobile } = useScreenSizeContext();

    useEffect(() => {
        const handleClickOutsideShippingPicker = (e: MouseEvent) => {
            if (shippingRef.current && e.target instanceof Node && !shippingRef.current.contains(e.target)) {
                setActiveShippingPicker(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideShippingPicker);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideShippingPicker);
        };
    }, [])

    const handleCouponTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCouponText(e.target.value);
    };

    const handleRewardsTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputString = e.target.value
        setRewardsText(adjustedRewardsAmount(inputString));
    };

    const adjustedRewardsAmount = (amountString: string) => {
        if (amountString == "0.0") {
            return amountString;
        }
        const amountNum = parseFloat(amountString)
        const adjustedNum = Math.max(0, Math.min(amountNum, Math.min(rewardsAvailable, costBeforeRewards - 1)))

        if (amountString.includes('.') && amountString.split('.')[1].length > 2) {
            return adjustedNum.toFixed(2);
        } else {
            return adjustedNum.toString();
        }
    }

    const applyRewards = (rewardsText: string) => {
        const rewardsNum = parseFloat(rewardsText);
        if (rewardsNum > 0) {
            setLastAppliedRewards(rewardsNum)
            handleApplyRewards(rewardsNum);
        }
    }

    useEffect(() => {
        setRewardsText(adjustedRewardsAmount(rewardsText))

        if (lastAppliedRewards > costBeforeRewards - 1) {
            const adjustedRewards = adjustedRewardsAmount(lastAppliedRewards.toString())
            applyRewards(adjustedRewards);
        }

    }, [costBeforeRewards]);

    useEffect(() => {
        if (hasShippingMethod) {
            const newShippingId: ShippingPricing = shippingMethods.filter(item => item.name === selectedShippingMethod.split(" - $")[0])[0]
            setShipmentId(newShippingId);
        }

    }, [selectedShippingMethod]);

    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value);
    };

    const handleOrderNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOrderNotes(e.target.value);
    };

    const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCardNumber(e.target.value);
    };

    const handleMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMonth(e.target.value);
    };

    const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length <= 2) {
            setYear(e.target.value);
        }
    };

    const handleCVVChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length <= 4) {
            setCVV(e.target.value);
        }
    };

    return (
        <div className={isMobile ? '' : 'card'}>
            <div className='text-l'>Payment Details</div>
            <div className='card-item'>
                <div className='row'>
                    <input
                        type="text"
                        value={firstName}
                        onChange={handleFirstNameChange}
                        placeholder={isMobile ? "First Name" : "Cardholder First Name"}
                        className='input'
                        required
                    />
                    <div className='gap-m' />
                    <input
                        type="text"
                        value={lastName}
                        onChange={handleLastNameChange}
                        placeholder={isMobile ? "Last Name" : "Cardholder Last Name"}
                        className='input'
                        required
                    />
                </div>
            </div>
            <div className='card-item'>
                <div className='row'>
                    <input
                        type="number"
                        value={cardNumber}
                        onChange={handleCardNumberChange}
                        placeholder="Card Number"
                        className='input'
                        required
                    />
                </div>
            </div>
            <div className='card-item'>
                <div className='row'>
                    <input
                        type="number"
                        value={month}
                        onChange={handleMonthChange}
                        placeholder="MM"
                        className='input
                        required'
                    />
                    <div className='gap-m' />
                    <input
                        type="number"
                        value={year}
                        onChange={handleYearChange}
                        placeholder="YY"
                        className='input'
                        required
                    />
                    <div className='gap-m' />
                    <input
                        type="number"
                        value={CVV}
                        onChange={handleCVVChange}
                        placeholder="CVV"
                        className='input'
                        required
                        max={9999}
                    />
                </div>
            </div>
            {expiryMessage !== null &&
                <div className='card-item'>
                    <div className='card-list'>
                        <div className={`text-xs-${expiryMessage.success ? "success" : "error"}`}>
                            {expiryMessage.message}
                        </div>
                    </div>
                </div>
            }
            <div className='divider-margin'></div>
            <div className='card-item'>
                <div className='text-s'>Promo Code</div>
                <div className='card-item'>
                    <div className='row'>
                        <input
                            type="text"
                            value={couponText}
                            onChange={handleCouponTextChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleApplyCoupon(couponText);
                                }
                            }}
                            placeholder={isMobile ? "Code" : "Promo Code"}
                            className='input'
                        />
                        <div className='gap-m' />
                        <button type="button" className='action-button-full' onClick={() => handleApplyCoupon(couponText)}>{isMobile ? "Apply" : "Apply Promo"}</button>
                        {couponApplied &&
                            <>
                                <div className='gap-m' />
                                <button className='filter-button' style={{ height: isMobile ? '40px' : '48px', borderRadius: '5px' }} onClick={handleClearCoupon}>
                                    <div className='col'>
                                        <FaTimes />
                                    </div>
                                </button>
                            </>
                        }
                    </div>
                </div>
                {couponMessage !== null &&
                    <div className='card-item'>
                        <div className='card-list'>
                            <div className={`text-xs-${couponMessage.success ? "success" : "error"}`}>
                                {couponMessage.message}
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className='card-item'>
                <div className='text-s'>Rewards Banks: ${rewardsAvailable.toFixed(2)}&nbsp;&nbsp;
                    <FaInfoCircle
                        data-tooltip-id="info-tooltip"
                        data-tooltip-content="Earn 5% on each purchase through 714tickets.com"
                        style={{ cursor: 'pointer', fontSize: '14px' }}
                    />
                    <Tooltip id="info-tooltip" />
                </div>
                <div className='card-item'>
                    <div className='row'>
                        <input
                            type="number"
                            value={rewardsText}
                            onChange={handleRewardsTextChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    applyRewards(rewardsText);
                                }
                            }}
                            placeholder={isMobile ? "Amount" : "Rewards Amount"}
                            className='input'
                            min="0"
                            step="0.01"
                        />
                        <div className='gap-m' />
                        <button type="button" className='action-button-full' onClick={() => applyRewards(rewardsText)}>{isMobile ? "Apply" : "Apply Rewards"}</button>
                        {rewardsApplied &&
                            <>
                                <div className='gap-m' />
                                <button className='filter-button' style={{ height: isMobile ? '40px' : '48px', borderRadius: '5px' }} onClick={handleClearRewards}>
                                    <div className='col'>
                                        <FaTimes />
                                    </div>
                                </button>
                            </>
                        }
                    </div>
                </div>
                {rewardsMessage !== null &&
                    <div className='card-item'>
                        <div className='card-list'>
                            <div className={`text-xs-${rewardsMessage.success ? "success" : "error"}`}>
                                {rewardsMessage.message}
                            </div>
                        </div>
                    </div>
                }
            </div>
            {hasShippingMethod &&
                <div className='card-item'>
                    <div className='text-s'>Shipping Method</div>
                    <div className='card-item'>
                        <div className='relative' ref={shippingRef}>
                            <button className={`filter-button ${false && 'filtered'}`} type="button" onClick={(() => setActiveShippingPicker(!activeShippingPicker))}>{selectedShippingMethod}</button>
                            {activeShippingPicker &&
                                <SingleSelectMenu
                                    options={shippingMethods.map(item => item.name + " - $" + item.cost.toFixed(2))}
                                    selectedOption={selectedShippingMethod}
                                    setSelectedOption={setSelectedShippingMethod}
                                    closeMenu={() => setActiveShippingPicker(false)}
                                    mustSelect={true}
                                    rightAligned={false}
                                />
                            }
                        </div>
                    </div>
                </div>
            }
            <div className='card-item'>
                <div className='text-s'>Special Order Notes</div>
                <div className='card-item'>
                    <div className='row'>
                        <input
                            type="text"
                            value={orderNotes}
                            onChange={handleOrderNotesChange}
                            placeholder="Special Order Notes"
                            className='input'
                        />
                    </div>
                </div>
            </div>
            {isMobile && chargesComponent}
            <div className='card-item'>
                <div className='row'>
                    <input
                        type="checkbox"
                        checked={upgrade}
                        onChange={() => setUpgrade(!upgrade)}
                        className='checkbox'
                    />
                    <div className='text-xs'>I authorize 714Tickets to provide a free automatic upgrade</div>
                </div>
            </div>
            <div className='card-item'>
                <button className='action-button-full' type="submit" onClick={() => (window.scrollTo(0, 0))}>Place Order</button>
            </div>
            <div className='card-item'>
                <div className='text-xs-light'>By clicking “Place Order”, you agree to the 714Tickets Terms and Conditions and confirm that you are aware that you are making a purchase on a resale marketplace and may be paying above face value for your tickets.</div>
            </div>

        </div>
    )
}

export default PaymentFormCard;
