import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import './Checkout.css';
import LogInFormCard from '../LogIn/LogInFormCard';
import SignUpFormCard from '../SignUp/SignUpFormCard';
import OrderDetailsCard from './OrderDetailsCard';
import OrderChargesCard from './OrderChargesCard';
import AddressFormCard from './AddressFormCard';
import PaymentFormCard from './PaymentFormCard';
import Listing from '../../interfaces/Listing';
import {applyCoupon, fetchCouponAndRewardsBoolean, fetchUserRewards, processTransaction} from '../../api/endpoints';
import {isRight} from 'fp-ts/lib/Either';
import {useAuthenticationContext} from '../../providers/AuthenticationProvider';
import Payment from '../../interfaces/Payment';
import Address from '../../interfaces/Address';
import Transaction from '../../interfaces/Transaction';
import Event from '../../interfaces/Event';
import ResponseMessage from '../../interfaces/ResponseMessage';
import TransactionResponseCard from './TransactionResponseCard';
import CouponVerificationDto from '../../interfaces/CouponVerificationDto';
import ShippingPricing from '../../interfaces/ShippingPricing';
import DeliveryMethod from '../../interfaces/DeliveryMethod';
import {useScreenSizeContext} from '../../providers/ScreenSizeProvider';


interface CheckoutProps {
    event: Event | null
    loadingEvent: boolean
    listing: Listing | null
    loadingListing: boolean
    selectedQuantity: number
}

const Checkout: React.FC<CheckoutProps> = ({ event, loadingEvent, listing, loadingListing, selectedQuantity }) => {
    const { isLoggedIn, isAuthLoading, user } = useAuthenticationContext();

    const [isLogIn, setisLogIn] = useState(true);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [CVV, setCVV] = useState('');

    const [mobilePhone, setMobilePhone] = useState((user != null && user.billPhoneNumber != null) ? user.billPhoneNumber : '');
    const [addressLine1, setAddressLine1] = useState((user != null && user.billAddress != null) ? user.billAddress : '');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState((user != null && user.billCity != null) ? user.billCity : '');
    const [zipCode, setZipCode] = useState((user != null && user.billZip != null) ? user.billZip : '');
    const [country, setCountry] = useState((user != null && user.billCountry != null) ? user.billCountry : '');
    const [state, setState] = useState((user != null && user.billState != null)? user.billState : '');
    const [saveBillingInfo, setSaveBillingInfo] = useState(true);

    const [coupon, setCoupon] = useState<string | null>(null);
    const [couponAmount, setCouponAmount] = useState(0);
    const [couponMessage, setCouponMessage] = useState<ResponseMessage | null>(null)

    const [rewardsAvailable, setRewardsAvailable] = useState(0);
    const [rewardsAmount, setRewardsAmount] = useState(0);
    const [rewardsMessage, setRewardsMessage] = useState<ResponseMessage | null>(null)

    const [couponAndRewardsAllowed, setCouponAndRewardsAllowed] = useState(false);

    const [expiryMessage, setExpiryMessage] = useState<ResponseMessage | null>(null)

    const [orderNotes, setOrderNotes] = useState('');

    const [upgrade, setUpgrade] = useState(true);

    const [loadingTransaction, setLoadingTransaction] = useState(false);
    const [transactionResponse, setTransactionResponse] = useState<any>(null);
    const [submittedTransaction, setSubmittedTransaction] = useState(0);
    const [transactionError, setTransactionError] = useState('');

    const [shipmentId, setShipmentId] = useState<ShippingPricing | null>(null);

    const { isMobile } = useScreenSizeContext();

    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        const fetchCouponAndRewards = async () => {
            const rewardsAndCouponAllowedResponseEither = await fetchCouponAndRewardsBoolean();
            if (isRight(rewardsAndCouponAllowedResponseEither)) {
                setCouponAndRewardsAllowed(rewardsAndCouponAllowedResponseEither.right);
            } else {
                console.error(rewardsAndCouponAllowedResponseEither.left)
            }
        }

        fetchCouponAndRewards();
    }, [])


    useEffect(() => {
        if (isAuthLoading) {
            navigate(location.pathname.replace('/login', '') + '/loading',  { replace: true })
            return
        }
        if (!isLoggedIn && !location.pathname.includes('/login')) {
            navigate((location.pathname + '/login').replace('/loading', ''),  { replace: true })
        }
        if (isLoggedIn) {
            navigate((location.pathname).replace('/login', '').replace('/loading', ''),  { replace: true })
        }

    }, [isLoggedIn, isAuthLoading])


    useEffect(() => {
        const updateRewards = async () => {
            if (!isLoggedIn) {
                setRewardsAvailable(0);
                return;
            }
            const rewardsEither = await fetchUserRewards();
            if (isRight(rewardsEither)) {
                setRewardsAvailable(rewardsEither.right);
            } else {
                setRewardsAvailable(0);
            }
        }

        updateRewards();
    }, [isLoggedIn]);

    const handleClickSignUp = () => {
        setisLogIn(false);
    }

    const handleClickLogIn = () => {
        setisLogIn(true);
    }

    const handleApplyCoupon = async (coupon: string) => {
        if (!loadingListing && listing !== null && !loadingEvent && event !== null) {
            if (!couponAndRewardsAllowed && rewardsAmount > 0) {
                setCouponMessage({ message: "Remove rewards to apply promo code", success: false })
            } else {
                const couponDto: CouponVerificationDto = { couponName: coupon, eventId: event.tevoEventId, listing: listing, desiredQuantity: selectedQuantity };
                const applyCoupontResponseEither = await applyCoupon(couponDto);
                if (isRight(applyCoupontResponseEither)) {
                    const amount = parseFloat(applyCoupontResponseEither.right)
                    setCouponAmount(amount)
                    setCoupon(coupon)
                    setCouponMessage({ message: `Promo code "${coupon}" to be applied for $${amount.toFixed(2)}`, success: true })
                } else {
                    setCouponAmount(0)
                    setCoupon(null);
                    setCouponMessage({ message: applyCoupontResponseEither.left, success: false })
                }
            }
        }
    }

    const handleApplyRewards = async (rewards: number) => {
        if (!couponAndRewardsAllowed && couponAmount > 0) {
            setRewardsMessage({ message: "Remove promo code to apply rewards", success: false })
        } else {
            setRewardsAmount(rewards);
            setRewardsMessage({ message: `Rewards to be applied: $${rewards.toFixed(2)}`, success: true })
        }
    }

    const handleClearCoupon = async () => {
        setCouponAmount(0)
        setCoupon('')
        setCouponMessage(null)
    }

    const handleClearRewards = async () => {
        setRewardsAmount(0);
        setRewardsMessage(null);
    }

    const handleSubmitOrder = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const currentYear = new Date().getFullYear();
        const fullYear = year.length === 2 ? parseInt('20' + year) : parseInt(year);
        const currentMonth = new Date().getMonth() + 1;


        if (!(fullYear > currentYear || (fullYear === currentYear && parseInt(month) >= currentMonth))) {
            setExpiryMessage({ message: `The submitted expiration date has already passed`, success: false })
            return;
        }


        if (listing !== null && event !== null) {
            setLoadingTransaction(true)
            const creditCard: Payment = { cardNumber: cardNumber, cardExpiry: month + year, cvv: CVV };
            const billingInfo: Address = { firstName: firstName, lastName: lastName, addressOne: addressLine1, addressTwo: addressLine2, city: city, country: country, state: state, zipCode: zipCode, phoneNumber: mobilePhone }
            const transaction: Transaction = { creditCard, billingInfo, tevoEventId: event.tevoEventId, listing, qty: selectedQuantity, rewardPoints: rewardsAmount, couponName: coupon, upgrade: upgrade, ...(shipmentId && { shipmentId: shipmentId.id }), orderNotes: orderNotes, saveBillingInfo: saveBillingInfo };
            const processTransactionResponseEither = await processTransaction(transaction);
            if (isRight(processTransactionResponseEither)) {
                setSubmittedTransaction(processTransactionResponseEither.right.id)
                setTransactionResponse(true)
                navigate(location.pathname + '/success',  { replace: true })
            } else {
                console.error(processTransactionResponseEither.left);
                setTransactionError(processTransactionResponseEither.left)
                setTransactionResponse(false)
            }
            setLoadingTransaction(false)
        }
    }



    return (
        <div className='container-info'>
            <div className={`${isMobile ? 'content-form' : 'content-main'}`}>
                <div className={`${isMobile ? "" : 'row align-start'}`}>
                    {loadingListing || loadingEvent ?
                        <div className="loading-spinner"></div>
                        :
                        (listing === null ?
                            <div>No Listing Found</div>
                            :
                            <>
                                <div className={`${isMobile ? '' : 'checkout-left-column'}`}>
                                    {!loadingTransaction ?
                                        transactionResponse == null || event == null ?
                                            !isLoggedIn ?
                                                <>
                                                    {isMobile && event !== null &&
                                                        <OrderDetailsCard event={event} listing={listing} selectedQuantity={selectedQuantity} />
                                                    }
                                                    {isMobile && <br/>}
                                                    {isMobile &&
                                                        <OrderChargesCard listing={listing} selectedQuantity={selectedQuantity} couponAmount={couponAmount} rewardsAmount={rewardsAmount} shippingMethod={shipmentId} />
                                                    }
                                                    {isMobile && <br/>}

                                                    {isLogIn ?
                                                        <LogInFormCard onClickSignUp={handleClickSignUp} />
                                                        :
                                                        <SignUpFormCard onClickLogIn={handleClickLogIn} />
                                                    }
                                                </>
                                                :
                                                <form onSubmit={handleSubmitOrder}>

                                                    {isMobile && event !== null &&
                                                        <OrderDetailsCard event={event} listing={listing} selectedQuantity={selectedQuantity} />
                                                    }
                                                    {isMobile && <div className="divider-margin"/>}
                                                    {isMobile && <br/>}
                                                    <AddressFormCard
                                                        mobilePhone={mobilePhone}
                                                        addressLine1={addressLine1}
                                                        addressLine2={addressLine2}
                                                        city={city}
                                                        country={country}
                                                        zipCode={zipCode}
                                                        state={state}
                                                        saveBillingInfo={saveBillingInfo}
                                                        setMobilePhone={setMobilePhone}
                                                        setAddressLine1={setAddressLine1}
                                                        setAddressLine2={setAddressLine2}
                                                        setCity={setCity}
                                                        setCountry={setCountry}
                                                        setZipCode={setZipCode}
                                                        setState={setState}
                                                        setSaveBillingInfo={setSaveBillingInfo}
                                                    />

                                                    {isMobile && <br/>}

                                                    <PaymentFormCard
                                                        firstName={firstName}
                                                        lastName={lastName}
                                                        cardNumber={cardNumber}
                                                        month={month}
                                                        year={year}
                                                        CVV={CVV}
                                                        rewardsAvailable={rewardsAvailable}
                                                        costBeforeRewards={listing.price * selectedQuantity - couponAmount}
                                                        couponApplied={couponAmount > 0}
                                                        rewardsApplied={rewardsAmount > 0}
                                                        couponMessage={couponMessage}
                                                        rewardsMessage={rewardsMessage}
                                                        expiryMessage={expiryMessage}
                                                        orderNotes={orderNotes}
                                                        upgrade={upgrade}
                                                        hasShippingMethod={listing.shippingMethod == DeliveryMethod.PHYSICAL}
                                                        setFirstName={setFirstName}
                                                        setLastName={setLastName}
                                                        setCardNumber={setCardNumber}
                                                        setMonth={setMonth}
                                                        setYear={setYear}
                                                        setCVV={setCVV}
                                                        setUpgrade={setUpgrade}
                                                        setShipmentId={setShipmentId}
                                                        handleApplyCoupon={handleApplyCoupon}
                                                        handleApplyRewards={handleApplyRewards}
                                                        handleClearCoupon={handleClearCoupon}
                                                        handleClearRewards={handleClearRewards}
                                                        setOrderNotes={setOrderNotes}
                                                        chargesComponent={<OrderChargesCard listing={listing} selectedQuantity={selectedQuantity} couponAmount={couponAmount} rewardsAmount={rewardsAmount} shippingMethod={shipmentId} />}
                                                    />
                                                </form>
                                            :
                                            <TransactionResponseCard success={transactionResponse} submittedTransaction={submittedTransaction} transactionError={transactionError} />
                                        :
                                        <div className="loading-spinner"></div>
                                    }

                                </div>
                                {!isMobile && event !== null &&
                                    <div className='checkout-right-column'>
                                        <OrderDetailsCard event={event} listing={listing} selectedQuantity={selectedQuantity} />
                                        <OrderChargesCard listing={listing} selectedQuantity={selectedQuantity} couponAmount={couponAmount} rewardsAmount={rewardsAmount} shippingMethod={shipmentId} />
                                    </div>
                                }
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Checkout;
