import React, {useEffect, useState} from 'react';
import {
    fetchGeneralBooleanSettings,
    fetchGeneralNumberSettings,
    setGeneralBooleanSetting,
    setGeneralNumberSetting
} from '../../api/endpoints';
import {isRight} from 'fp-ts/lib/Either';
import GeneralSettingNumber from '../../interfaces/GeneralSettingNumber';
import GeneralSettingBoolean from '../../interfaces/GeneralSettingBoolean';


const AdminGeneralSettings: React.FC = () => {

    const [loadingSettings, setLoadingSettings] = useState(true)
    const [generalSettingNumbers, setGeneralSettingNumbers] = useState<GeneralSettingNumber[]>([])
    const [generalSettingBooleans, setGeneralSettingBooleans] = useState<GeneralSettingBoolean[]>([])

    const fetchSettings = async () => {
        setLoadingSettings(true);
        const booleansResponseEither = await fetchGeneralBooleanSettings();
        const numbersResponseEither = await fetchGeneralNumberSettings();

        if (isRight(booleansResponseEither)) {
            setGeneralSettingBooleans(booleansResponseEither.right);
        } else {
            console.error(booleansResponseEither.left)
        }

        if (isRight(numbersResponseEither)) {
            setGeneralSettingNumbers(numbersResponseEither.right);
        } else {
            console.error(numbersResponseEither.left)
        }
        setLoadingSettings(false)
    };


    useEffect(() => {
        fetchSettings();
    }, []);


    const handleToggleValueChange = (toggleIndex: number, value: boolean) => {
        const updatedBooleans = [...generalSettingBooleans];
        updatedBooleans[toggleIndex].value = value;
        setGeneralSettingBooleans(updatedBooleans);
    }

    const handleNumberValueChange = (numberIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedNumbers = [...generalSettingNumbers];
        updatedNumbers[numberIndex].value = parseFloat(e.target.value);
        setGeneralSettingNumbers(updatedNumbers);
    }

    const handleSubmitChanges = async () => {
        setLoadingSettings(true);
        for (let i = 0; i < generalSettingBooleans.length; i++) {
            const setBooleanResponseEither = await setGeneralBooleanSetting(generalSettingBooleans[i]);
            if (isRight(setBooleanResponseEither)) {
            } else {
                console.error(setBooleanResponseEither.left)
            }
        }

        for (let i = 0; i < generalSettingNumbers.length; i++) {
            const setNumberResponseEither = await setGeneralNumberSetting(generalSettingNumbers[i]);
            if (isRight(setNumberResponseEither)) {
            } else {
                console.error(setNumberResponseEither.left)
            }
        }

        fetchSettings();
    }

    return (
        <div className='card card-top' >
            <div className='row'>
                <div className='left'>
                    <div className='text-l'>General Settings</div>
                </div>
                <button className='admin-action-button' onClick={handleSubmitChanges}>Save Changes</button>
            </div>
            {loadingSettings ?
                <div className="loading-spinner" />
                :
                <>
                    <div className='card-item'>
                        <div className='text-s-bold'>
                            Toggles
                        </div>
                    </div>
                    {generalSettingBooleans.map((setting, settingIndex) => (
                        <div className='row'>
                            <div className='text-xs-bold'>{setting.name}:&nbsp;</div>
                            <input type="checkbox" checked={setting.value} onChange={() => handleToggleValueChange(settingIndex, !setting.value)} className='admin-checkbox' />
                        </div>
                    ))
                    }
                    <br />
                    <div className='card-item'>
                        <div className='text-s-bold'>
                            Values
                        </div>
                    </div>
                    {generalSettingNumbers.map((setting, settingIndex) => (
                        <div className='row'>
                            <div className='text-xs-bold'>{setting.name}:&nbsp;</div>
                            <input type="number" min="0" step="0.01" value={setting.value} onChange={handleNumberValueChange(settingIndex)} className='admin-input' />
                        </div>
                    ))
                    }
                </>
            }
        </div>
    )
}

export default AdminGeneralSettings;
