import React, {useEffect, useState} from 'react';
import {Navigate, useParams} from "react-router-dom";
import Footer from '../components/Footer/Footer';
import {fetchEventsByQuery} from '../api/endpoints';
import Event from '../interfaces/Event';
import Navbar from '../components/Navbar/Navbar';
import {isRight} from 'fp-ts/lib/Either';
import SearchResultsHeader from '../components/HomeSearch/SearchResultsHeader';
import QueryEvents from "../components/Events/QueryEvents";


const SearchResultsPage: React.FC = () => {
    const { encodedQuery = '' } = useParams<{ encodedQuery: string }>();
    const query: string = React.useMemo(() => decodeURIComponent(encodedQuery), [encodedQuery]);

    const [events, setEvents] = useState<Event[]>([]);
    const [loadingEvents, setLoadingEvents] = useState<boolean>(true);

    useEffect(() => {
        const fetchEvents = async () => {
            if (query !== '') {
                setLoadingEvents(true);
                const eventsDataResponseEither = await fetchEventsByQuery(query, false, 1);
                if (isRight(eventsDataResponseEither)) {
                    setEvents(eventsDataResponseEither.right);
                } else {
                    console.error(eventsDataResponseEither.left)
                }
                setLoadingEvents(false);
            }
        };

        fetchEvents();
    }, [query]);


    if (query == '') {
        return <Navigate to="/not-found" />
    }

    return (
        <div>
            <Navbar key={query} showSearch={true}/>
            <SearchResultsHeader query={query}/>
            <QueryEvents query={query} initialEvents={events} loadingEvents={loadingEvents} />
            <Footer/>
        </div>
    )
}

export default SearchResultsPage;
