import React from 'react';
import ForgotPasswordFormCard from './ForgotPasswordFormCard';


const SignUp: React.FC = () => {
    return (
        <div className='container-info'>
            <div className='content-form'>
                <ForgotPasswordFormCard/>
            </div>
        </div>
    )
}

export default SignUp;
