import React, {useEffect, useState} from 'react';
import './EventMap.css';
import {useReadBlobContext} from '../../providers/ReadBlobProvider';


interface EventMapImageProps {
    mapImageLocation: string
}

const EventMapImage: React.FC<EventMapImageProps> = ({mapImageLocation}) => {

    const {imageReadBlobService} = useReadBlobContext();
    const [imageUrl, setImageUrl] = useState('');
    const [loadingImage, setLoadingImage] = useState(true);
    const [imageExists, setImageExists] = useState(true);

    useEffect(() => {
        if (imageReadBlobService !== null) {
            setLoadingImage(true);
            const mapImageUrl = imageReadBlobService.getUrlIfPathExists("maps/" + mapImageLocation + "/img.jpg")
            if (mapImageUrl) {
                setImageExists(true);
                setImageUrl(mapImageUrl)
            } else {
                setImageExists(false);
            }
            setLoadingImage(false)
        }

      }, [imageReadBlobService, mapImageLocation]);

    return (
        <div className='event-map-container' >
            {loadingImage ? <div className="loading-spinner"></div> :
                !imageExists ? <div>No Map Available</div> :
                <div className='map-image-container' style={{ backgroundImage: `url(${imageUrl})` }}></div>
            }
        </div>
    )
}

export default EventMapImage;
