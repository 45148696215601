import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import './HomeSuggestions.css';
import Performer from '../../interfaces/Performer';
import {getEventUrlParams, getPerformerUrlParams} from '../../utils/GlobalUtils';
import {useReadBlobContext} from '../../providers/ReadBlobProvider';
import ListingSource from '../../interfaces/ListingSource';
import Event from '../../interfaces/Event';
import {useGlobalContext} from '../../providers/GlobalProvider';
import {useScreenSizeContext} from '../../providers/ScreenSizeProvider';


interface SuggestionsRowProps {
    title: string;
    suggestions: Performer[];
}

const SuggestionsRow: React.FC<SuggestionsRowProps> = ({ title, suggestions }) => {

    const {isMobile} = useScreenSizeContext();
    const { imageReadBlobService } = useReadBlobContext();

    return (
        <div className='category-container'>
            <div className='text-m'>{title}</div>
            <div className={`${isMobile ? "col" : "row"} align-start`}>
                {suggestions.map(performer => {
                    const suggestionImage = imageReadBlobService?.getUrlIfPathExists("suggestions/" + performer.slug + "/img.jpg");
                    const imageUrl = (suggestionImage == '') ? null : suggestionImage;
                    return (
                        <div className='suggestion-card-container'>
                            {performer.category.tevoCategoryId == -1 ?
                                <Link
                                    key={performer.tevoPerformerId}
                                    to={`/event/${getEventUrlParams({tevoEventId: performer.tevoPerformerId, name: performer.name, occursAtLocal: '2000-01-01T00:00:00-00:00', venue: {tevoVenueId: -1, name: "", location: ""}, configuration: {tevoConfigurationId: -1, name: ''}, listingSource: ListingSource.CUSTOM} as Event)}`}
                                    className='suggestion-card'
                                    style={{ backgroundImage: imageUrl ? `url(${imageUrl})` : undefined, backgroundSize: 'cover', backgroundPosition: 'center', }}                                />
                                :
                                <Link
                                    key={performer.tevoPerformerId}
                                    to={`/performer/${getPerformerUrlParams(performer)}`}
                                    className='suggestion-card'
                                    style={{ backgroundImage: imageUrl ? `url(${imageUrl})` : undefined, backgroundSize: 'cover', backgroundPosition: 'center', }}                                />
                            }


                            <div className='suggestion-text-container'>
                                <div className={`${isMobile ? "text-xs-bold" : "text-s"}`}>
                                    {performer.name}
                                </div>
                                <div className={`${isMobile ? "text-xxs" : "text-xs-light"}`}>
                                    {performer.category.name}
                                </div>
                            </div>
                        </ div>
                    )
                })}
            </div>

        </div>
    );
}

const HomeSuggestions: React.FC = () => {
    const { suggestions } = useGlobalContext();
    const { isMobile } = useScreenSizeContext();

    return (
        <div className='container-main'>
            <div className='content-main tall'>
                {!isMobile && <div className='left text-xl'>Trending Events</div>}
                { suggestions.length > 0 ?
                    <>
                        {suggestions.map(category => (
                            <SuggestionsRow key={category.name} title={category.name} suggestions={category.performers} />
                        ))}
                    </>
                    :
                    <div className="loading-spinner"></div>
                }
            </div>
        </div>
    );
}

export default HomeSuggestions;
