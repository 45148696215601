import React, {useEffect, useState,} from 'react';
import Navbar from '../components/Navbar/Navbar';
import EventView from '../components/EventView/EventView';
import {useNavigate, useParams} from 'react-router-dom';
import Event from '../interfaces/Event';
import Listing from '../interfaces/Listing';
import {fetchEventById, fetchListingsByEvent} from '../api/endpoints';
import {isRight} from 'fp-ts/lib/Either';
import {canBeParsedToInt} from '../utils/GlobalUtils';
import ListingSource from '../interfaces/ListingSource';
import MobileLoading from "../components/MobileModals/MobileLoading";
import {useScreenSizeContext} from "../providers/ScreenSizeProvider";


const EventPage: React.FC = () => {
    const { eventName, isCustom = '', eventId = ''} = useParams<{ eventName: string, isCustom: string, eventId: string }>();
    const [event, setEvent] = useState<Event | null>(null)
    const [loadingEvent, setLoadingEvent] = useState<boolean>(true);

    const [listings, setListings] = useState<Listing[]>([]);
    const [loadingListings, setLoadingListings] = useState<boolean>(true);

    const navigate = useNavigate();
    const { isMobile } = useScreenSizeContext();

    useEffect(() => {

        const fetchEvent = async () => {
            setLoadingEvent(true);
            const eventDataEither = await fetchEventById(parseInt(eventId), (isCustom == "0" ? ListingSource.TEVO : ListingSource.CUSTOM));
            if (isRight(eventDataEither)) {
                setEvent(eventDataEither.right);
            } else {
                navigate(`/not-found`);
            }
            setLoadingEvent(false);
        }

        const fetchListings = async () => {
            setLoadingListings(true);
            const listingsDataEither = await fetchListingsByEvent(parseInt(eventId), isCustom == "0" ? ListingSource.TEVO : ListingSource.CUSTOM);
            if (isRight(listingsDataEither)) {
                setListings(listingsDataEither.right);
            } else {
                setListings([]);
            }
            setLoadingListings(false);
        };

        if (canBeParsedToInt(eventId) && (isCustom === "0" || isCustom === "1")) {
            fetchEvent();
            fetchListings();

        } else {
            navigate(`/not-found`);
        }

    }, []);


    return (
        <div style={{backgroundColor: 'rgb(245, 245, 245)'}}>
            {isMobile &&
                <MobileLoading loading={loadingEvent || loadingListings}/>
            }
            <Navbar showSearch={true} fullScreen={true} alwaysSolid={true} />
            <EventView event={event} loadingEvent={loadingEvent} listings={listings} loadingListings={loadingListings} />
        </div>
    )
}

export default EventPage;
