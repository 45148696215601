import React from 'react';
import logo from '../../assets/714-logo-hover.svg';

interface ResetPasswordResponseCardProps {
    success: boolean;
}

const ResetPasswordResponseCard: React.FC<ResetPasswordResponseCardProps> = ({success}) => {

    return (
        <div className='card'>
            <div className='text-l'>{success ? "Password Reset!" : "Issue Resetting Password"}</div>
            <div className='card-item'>
                <div className='text-s-light'>
                    {success ? "Your password has been reset.": "Please try again..."}
                </div>
            </div>
            <br/>
            <img src={logo} alt="714 Logo" style={{ width: '140px', height: 'auto', marginBottom: "4px" }} />
        </div>
    )
}

export default ResetPasswordResponseCard;
