import React from 'react';
import VerificationCard from './VerificationCard';


const Verification: React.FC = () => {
    return (
        <div className='container-info'>
            <div className='content-form'>
                <VerificationCard/>
            </div>
        </div>
    )
}

export default Verification;
