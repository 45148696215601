import React, {useEffect, useRef, useState} from 'react';
import Navbar from "../components/Navbar/Navbar"
import HomeSearch from '../components/HomeSearch/HomeSearch';
import HomeSuggestions from '../components/HomeSuggestions/HomeSuggestions'
import Banner from '../components/Banner/Banner';
import Footer from '../components/Footer/Footer';
import TagManager from "react-gtm-module";
import ReactGA from 'react-ga4';



const HomePage: React.FC = () => {
    const [showHeader, setShowHeader] = useState(true);
    const navBarRef = useRef<{triggerMobileDropdownSearch: () => void}>(null);

    const handleMobileSearchPressed = () => {
        if (navBarRef.current) {
            navBarRef.current.triggerMobileDropdownSearch();
        }
    }

    useEffect(() => {
        // Google Analytics
        ReactGA.send({ hitType: 'pageview', page: "home-page"});

        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition < 10) {
                setShowHeader(true);
            } else {
                setShowHeader(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const headerText = "714Tickets is a resale marketplace, not a box office or venue. Ticket prices may be below or above face value."

    return (
        <div>
             {/*{showHeader && <div className='header'>{headerText}</div>}*/}
            <Navbar ref={navBarRef}/>
            <HomeSearch onMobileSearchPressed={handleMobileSearchPressed}/>
            <HomeSuggestions/>
            <Banner/>
            <Footer/>
        </div>
    )
}

export default HomePage;
