import React from 'react';
import './HomeSearch.css';
import SearchBar from '../SearchBar/SearchBar';

interface HomeSearchProps {
    onMobileSearchPressed?: () => void;
}
const HomeSearch: React.FC<HomeSearchProps> = ({onMobileSearchPressed = () => null}) => {

    return (
        <div className='search-hero-container'>
            <div className='hero-title'>
                THE PRICE YOU SEE <br />IS THE PRICE YOU PAY
            </div>
            <br/>
           <SearchBar onMobileSearchPressed={onMobileSearchPressed} />
        </div>
    )
}

export default HomeSearch;