import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import LogIn from '../components/LogIn/LogIn';

const LoginPage: React.FC = () => {

    return (
        <div>
            <Navbar showSearch={true} alwaysSolid={true}/>
            <LogIn/>
            <Footer/>
        </div>
    )
}

export default LoginPage;
