import React, {useEffect, useState} from 'react';
import './Heros.css';
import Performer from '../../interfaces/Performer';
import {useReadBlobContext} from '../../providers/ReadBlobProvider';


interface PerformerHeroProps {
    performer: Performer | null;
    loadingPerformer: boolean;
}

const PerformerHero: React.FC<PerformerHeroProps> = ({performer, loadingPerformer}) => {
    const {imageReadBlobService} = useReadBlobContext();
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (imageReadBlobService !== null && performer !== null) {
            const slugImageUrl = imageReadBlobService.getUrlIfPathExists("performers/specific/" + performer.slug + "/img.jpg")
            if (slugImageUrl) {
                setImageUrl(slugImageUrl)
            } else {
                const categoryImageUrl = imageReadBlobService.getImageUrlForCategoryPath(performer.category.path)
                setImageUrl(categoryImageUrl)
            }
        }

      }, [imageReadBlobService, performer]);

    useEffect(() => {
        if (imageUrl) {
            const img = new Image();
            img.src = imageUrl;
            img.onload = () => setIsLoaded(true);
        }
      }, [imageUrl]);


    return (
        <div className= "hero-container" style={{ backgroundImage: `url(${imageUrl})` }} >
            {(isLoaded && !loadingPerformer && performer !== null) ?
                <>
                    <div className='hero-title'>
                        {performer.name.toUpperCase()}
                    </div>
                    <div style={{height: "5px"}}></div>
                    <div className='hero-subtitle'>{performer.category.name}</div>
                </>
                :
                <div className="loading-spinner" style={{borderLeftColor: "white"}}></div>
            }
        </div>
    )
}

export default PerformerHero;
