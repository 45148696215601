import React, {useEffect, useState} from 'react';
import Navbar from '../components/Navbar/Navbar';
import Checkout from '../components/Checkout/Checkout';
import Footer from '../components/Footer/Footer';
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {canBeParsedToInt} from '../utils/GlobalUtils';
import Listing from '../interfaces/Listing';
import {fetchEventById, fetchListingById} from '../api/endpoints';
import {isRight} from 'fp-ts/lib/Either';
import Event from '../interfaces/Event';
import ListingSource from '../interfaces/ListingSource';
import MobileLoading from "../components/MobileModals/MobileLoading";
import {useScreenSizeContext} from "../providers/ScreenSizeProvider";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";


const CheckoutPage: React.FC = () => {

    const { selectedQuantity = '', isCustom = '', eventId = '', listingId = ''} = useParams<{ selectedQuantity: string, isCustom: string, eventId: string, listingId: string }>();
    const [event, setEvent] = useState<Event | null>(null)
    const [loadingEvent, setLoadingEvent] = useState<boolean>(true);

    const [listing, setListing] = useState<Listing | null>(null);
    const [loadingListing, setLoadingListing] = useState(true);

    const navigate = useNavigate();
    const { isMobile } = useScreenSizeContext();

    useEffect(() => {
        // Google Analytics
        ReactGA.send({ hitType: 'pageview', page: "checkout-page"});

        const fetchEvent = async () => {
            setLoadingEvent(true);
            const eventDataEither = await fetchEventById(parseInt(eventId), (isCustom == "0" ? ListingSource.TEVO : ListingSource.CUSTOM));
            if (isRight(eventDataEither)) {
                setEvent(eventDataEither.right);
            } else {
                navigate(`/not-found`);
            }
            setLoadingEvent(false);
        }

        const fetchListing = async () => {
            setLoadingListing(true);
            const listingEither = await fetchListingById(listingId, parseInt(eventId), isCustom == "0" ? ListingSource.TEVO : ListingSource.CUSTOM);

            if (isRight(listingEither)) {
                setListing(listingEither.right);
            } else {
                navigate(`/not-found`);
            }
            setLoadingListing(false);
        };

        if (canBeParsedToInt(eventId) && canBeParsedToInt(listingId) && (isCustom === "0" || isCustom === "1")) {
            fetchEvent();
            fetchListing();

        } else {
            navigate(`/not-found`);
        }

    }, []);


    if (!canBeParsedToInt(selectedQuantity)) {
        return <Navigate to="/not-found" />
    }


    return (
        <div>
            {isMobile &&
                <MobileLoading loading={loadingEvent || loadingListing}/>
            }
            <Navbar showSearch={true} alwaysSolid={true}/>
            <Checkout event={event} loadingEvent={loadingEvent} listing={listing} loadingListing={loadingListing} selectedQuantity={parseInt(selectedQuantity)}/>
            <Footer/>
        </div>
    )
}

export default CheckoutPage;
