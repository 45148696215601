import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import MarkdownCard from '../components/MarkdownCard/MarkdownCard';


const TermsAndConditionsPage: React.FC = () => {

    return (
        <div>
            <Navbar showSearch={true} alwaysSolid={true}/>
            <div className='container-info'>
                <div className='content-form'>
                    <MarkdownCard filePath="/markdown/terms.md" />
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default TermsAndConditionsPage;
