import React from 'react';
import './HomeSearch.css';


interface SearchResultsHeaderProps {
    query: string;
 }
const SearchResultsHeader: React.FC<SearchResultsHeaderProps> = ({query}) => {

    return (
        <div className='results-hero-container'>
            <br/><br/>
            <h1 className='hero-title'>
                Results for "{query}"
            </h1>
        </div>
    )
}

export default SearchResultsHeader;
