import React, {useEffect, useState} from 'react';
import ReactMarkdown from 'react-markdown';
import logo from '../../assets/714-logo-hover.svg';
import {useScreenSizeContext} from '../../providers/ScreenSizeProvider';

interface MarkdownCardProps {
    filePath: string;
}

const MarkdownCard: React.FC<MarkdownCardProps> = ({ filePath }) => {
    const [content, setContent] = useState('');
    const {isMobile} = useScreenSizeContext();

    useEffect(() => {
        const fetchTerms = async () => {
            const response = await fetch(filePath);
            const text = await response.text();
            setContent(text);
        }
        fetchTerms();
    }, [])


    return (
        <div className={isMobile ? ' markdown-body' : 'card  markdown-body'}>
            {!isMobile &&
            <div className='row'>
                <div className='left'>
                </div>
                <img src={logo} alt="714 Logo" style={{ width: '140px', height: 'auto', marginBottom: "-70px" }} />
            </div>}

            <ReactMarkdown children={content} />
        </div>
    )
}

export default MarkdownCard;
