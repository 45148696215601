import axios from 'axios'

const baseURL = process.env.REACT_APP_BACKEND_URL;

const apiClientAuth = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    headers: {
        mode: "no-cors",
        'Access-Control-Allow-Origin': "*",
    },
});

export default apiClientAuth;
