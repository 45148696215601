import CheckoutItem from "../interfaces/CheckoutItem";
import Event from "../interfaces/Event";
import ListingSource from "../interfaces/ListingSource";
import Performer from "../interfaces/Performer";
import Venue from "../interfaces/Venue";

export const encodeObject = (obj: any) => {
    return btoa(encodeURIComponent(JSON.stringify(obj)));
}

export const getEventUrlParams = (event: Event) => {
    return event.name.toLowerCase().replaceAll(" ", "-").replaceAll("/", "-") + "/" + (event.listingSource == ListingSource.TEVO ? "0" : "1") + "/" + event.tevoEventId
}

export const getPerformerUrlParams = (performer: Performer) => {
    return performer.name.toLowerCase().replaceAll(" ", "-").replaceAll("/", "-") + "/" + performer.tevoPerformerId
}

export const getVenueUrlParams  = (venue: Venue) => {
    return venue.name.toLowerCase().replaceAll(" ", "-").replaceAll("/", "-") + "/" + venue.tevoVenueId
}

export const getCheckoutItemUrlParams = (checkoutItem: CheckoutItem) => {
    const test =  (checkoutItem.listingSource == ListingSource.TEVO ? "0" : "1") + "/" + checkoutItem.eventId + "/" + checkoutItem.selectedQuantity + "/" +  checkoutItem.listingId
    return test
}

export const canBeParsedToInt = (str: string) => {
    const num = parseInt(str, 10);
    return !isNaN(num) && num.toString() === str.trim();
};

export const resizeImage = async (file: File, maxWidth: number): Promise<File> => {
    return new Promise((resolve) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
            const canvas = document.createElement('canvas');
            const aspectRatio = img.height / img.width;

            canvas.width = maxWidth;
            canvas.height = maxWidth * aspectRatio;

            const ctx = canvas.getContext('2d');
            ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

            canvas.toBlob((blob) => {
                if (blob) {
                    const resizedFile = new File([blob], file.name, {
                        type: file.type,
                    });
                    resolve(resizedFile);
                }
            }, file.type);

            URL.revokeObjectURL(img.src);
        };
    });
};

export const decodeObject = (encoding: string) => {
    try {
        return JSON.parse(decodeURIComponent(atob(encoding)));
    } catch {
        return null;
    }
}
