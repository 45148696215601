import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import './SearchBar.css';
import {RiSearchLine} from 'react-icons/ri';
import {fetchSearchResultByQuery} from '../../api/endpoints';
import SearchResult from '../../interfaces/SearchResult';
import {formatTimeZonedDateToFullDate} from '../../utils/DateUtils/DateUtils';
import {getEventUrlParams, getPerformerUrlParams, getVenueUrlParams} from '../../utils/GlobalUtils';
import {isRight} from "fp-ts/Either";


interface SearchMenuProps {
    results: SearchResult;
    setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
}
const SearchMenu: React.FC<SearchMenuProps> = ({ results, setIsFocused }) => {

    return (
        <div className="search-menu-nav">
            {results.performers.length > 0 || results.events.length > 0 || results.venues.length > 0 || results.featured.length > 0 ?
                <>
                    {results.featured.length > 0 &&
                        <>
                            <div className='menu-title'>Featured</div>
                            {results.featured.map(feature => (
                                <Link
                                    key={feature.tevoEventId}
                                    to={`/event/${getEventUrlParams(feature)}`}
                                    className="menu-item"
                                >
                                    <div className='text-xs-bold'>{feature.name}</div>
                                    <div className='text-xs-light'>{formatTimeZonedDateToFullDate(feature.occursAtLocal)}</div>
                                </Link>
                            ))}
                        </>
                    }
                    {results.performers.length > 0 &&
                        <>
                            <div className='menu-title'>Performers</div>
                            {results.performers.map(performer => (
                                <Link
                                    key={performer.tevoPerformerId}
                                    to={`/performer/${getPerformerUrlParams(performer)}`}
                                    className="menu-item"
                                    onClick={() => setIsFocused(false)}
                                >
                                    <div className='text-xs-bold'>{performer.name}</div>
                                    <div className='text-xs-light'>{performer.category.name}</div>
                                </Link>))}
                        </>
                    }
                    {/* Events results, in case they are ever wanted
                        {results.events.length > 0 &&
                        <>
                            <div className='menu-title'>Events</div>
                            {results.events.map(event => (
                                <Link
                                    key={event.tevoEventId}
                                    to={`/event/${encodeObject(event)}`}
                                    className="menu-item"
                                    onClick={() => setIsFocused(false)}
                                >
                                    <div className='text-xs-bold'>{event.name}</div>
                                    <div className='text-xs-light'>{formatTimeZonedDateToFullDate(event.occursAtLocal)}</div>
                                </Link>
                            ))}
                        </>
                    } */}

                    {results.venues.length > 0 &&
                        <>
                            <div className='menu-title'>Venues</div>
                            {results.venues.map(venue => (
                                <Link
                                    key={venue.tevoVenueId}
                                    to={`/venue/${getVenueUrlParams(venue)}`}
                                    className="menu-item"
                                    onClick={() => setIsFocused(false)}
                                >
                                    <div className='text-xs-bold'>{venue.name}</div>
                                    <div className='text-xs-light'>{venue.location}</div>
                                </Link>
                            ))}
                        </>
                    }
                </>
                :
                <div className='text-xs-bold' style={{margin: "5px 10px"}}>No Search Results</div>
            }
        </div>
    );
};

const SearchBarNav: React.FC = () => {
    const [query, setQuery] = useState('');
    const lastSearchedRef = useRef('');
    const [isFocused, setIsFocused] = useState(false);
    const [results, setResults] = useState<SearchResult | null>(null);
    const [loading, setLoading] = useState(false);

    const searchRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const handleEnter = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const q = query.trim().toLowerCase();
        if (!q || q == '') {
            return;
        }

        if (results) {
            for (let i = 0; i < results.performers.length; i++) {
                if (results.performers[i].name.toLowerCase() == q) {
                    navigate(`/performer/${getPerformerUrlParams(results.performers[i])}`)
                    return
                }
            }

            // Auto-navigate for event match
            // for (let i = 0; i < results.events.length; i++) {
            //     if (results.events[i].name.toLowerCase() == q) {
            //         navigate(`/event/${getEventUrlParams(results.events[i])}`)
            //         return
            //     }
            // }

            for (let i = 0; i < results.venues.length; i++) {
                if (results.venues[i].name.toLowerCase() == q) {
                    navigate(`/venue/${getVenueUrlParams(results.venues[i])}`)
                    return
                }
            }
        }

        navigate(`/search/${encodeURIComponent(query.trim())}`);
    };

    useEffect(() => {
        const handleClickOutsideSearch = (e: MouseEvent) => {
            if (searchRef.current && e.target instanceof Node && !searchRef.current.contains(e.target)) {
                setIsFocused(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideSearch);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideSearch);
        };
    }, []);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        if (e.target.value.length == 1) {
            fetchResults(e.target.value)
        }
    };

    const handleFocus = () => {
        setIsFocused(true);
    };


    const fetchResults = async (query: string) => {
        if (query === '') {
            setResults(null);
            return;
        }
        setLoading(true);
        lastSearchedRef.current = query;

        const resultResponseEither = await fetchSearchResultByQuery(query);

        if (isRight(resultResponseEither)) {
            const result = resultResponseEither.right;
            if (lastSearchedRef.current === result.query) {
                setResults(result)
            }
        } else {
            console.error(resultResponseEither.left)
        }
        setLoading(false);
    }


    const debounceFetchResults = useCallback(
        (() => {
            let timeoutId: NodeJS.Timeout;
            return (searchQuery: string) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    fetchResults(searchQuery);
                }, 300);
            };
        })(),
        []
    );

    useEffect(() => {
        debounceFetchResults(query);
    }, [query, debounceFetchResults]);


    return (
        <div ref={searchRef}>
            <form className={`search-form-nav ${isFocused ? 'focused-form-nav' : ''}`} onSubmit={handleEnter}>
                {!loading ?
                    <RiSearchLine className={`search-icon-nav ${isFocused ? 'focused-icon-nav' : ''}`} />
                    :
                    <div className={`search-loading-spinner-nav ${isFocused ? 'focused-search-loading-spinner' : ''}`}></div>
                }                  <input
                    type="text"
                    value={query}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    placeholder="Search performers, events, venues..."
                    className={`search-input-nav ${isFocused ? 'focused-input-nav' : ''}`}
                />
            </form>
            {isFocused && results !== null && <SearchMenu results={results} setIsFocused={setIsFocused} />}
        </div>
    )
}

export default SearchBarNav;
