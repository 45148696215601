import React, {createContext, useContext, useEffect, useState} from 'react';
import {AnonymousCredential, BlobServiceClient, ContainerClient} from '@azure/storage-blob';
import {fetchReadEticketsSASToken} from "../api/endpoints";
import {isRight} from "fp-ts/lib/Either";


export class ReadBlobService {

    private containerClient: ContainerClient;
    private blobPathSet: Set<string>;

    private constructor(containerClient: ContainerClient) {
        this.containerClient = containerClient;
        this.blobPathSet = new Set<string>();
    }

    static async create(containerName: string, containerSAS: string | null): Promise<ReadBlobService> {
        const blobUrl = process.env.REACT_APP_BLOB_URL as string;
        const blobServiceClient = (containerSAS == null) ? new BlobServiceClient(blobUrl, new AnonymousCredential()) :  new BlobServiceClient(blobUrl + "?" + containerSAS);
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const instance = new ReadBlobService(containerClient);
        if (!containerSAS) {
            instance.blobPathSet = await instance.createBlobPathSet();
        }
        return instance;
    }

    private async createBlobPathSet(): Promise<Set<string>> {
        let blobNames: Set<string> = new Set();
        for await (const blob of this.containerClient.listBlobsFlat()) {
            blobNames.add(blob.name);
        }
        return blobNames;
    }

    getImageUrlForCategoryPath(categoryPath: string) {
        const pathSegments = categoryPath.split('/');
        for (let i = pathSegments.length; i > 0; i--) {
            const currentPath = pathSegments.slice(0, i).join('/') + "/img.jpg";
                if (this.blobPathSet.has(currentPath)) {
                    const blobClient = this.containerClient.getBlobClient(currentPath);
                    return blobClient.url;
                }
        }

        return '';
    }

    getUrlIfPathExists(path: string) {
        const imagePath = path
        if (this.blobPathSet.has(imagePath)) {
            const blobClient = this.containerClient.getBlobClient(imagePath);
            return blobClient.url;
        }
        return null
    }

    static async getFileUrlIfPathExists(filePath: string, orderId: number) {
        const eticketsContainer = process.env.REACT_APP_ETICKETS_CONTAINER as string;
        const eticketsKeyResponseEither = await fetchReadEticketsSASToken(orderId, filePath);
        if (!isRight(eticketsKeyResponseEither)) {
            return null;
        }

        const eticketsKey = eticketsKeyResponseEither.right;
        const speificReadBlobService = await ReadBlobService.create(eticketsContainer, eticketsKey);

        const blobClient = speificReadBlobService.containerClient.getBlobClient(filePath);
        return blobClient.url;
    }

    getExactUrlForPath(path: string) {
        const imagePath = path
        const blobClient = this.containerClient.getBlobClient(imagePath);
        return blobClient.url;
    }

    async getAllPathsFromBase(basePath: string) {
        return await ReadBlobService.getAllPrefixBlobs(this.containerClient, basePath);
    }

    static async getAllPrefixBlobs(containerClient: ContainerClient, prefix: string) {
        const blobNames: string[] = [];
        for await (const item of containerClient.listBlobsFlat({ prefix })) {
            if (item.name.endsWith("/")) {
                continue;
            }
            blobNames.push(item.name);
        }
        return blobNames;
    }
}


interface ReadBlobContextType {
    imageReadBlobService: ReadBlobService | null;
}

const ReadBlobContext = createContext<ReadBlobContextType>({
    imageReadBlobService: null,
});

export const useReadBlobContext = () => useContext(ReadBlobContext);

interface ReadBlobProviderProps {
    children: React.ReactNode;
}

export const ReadBlobProvider: React.FC<ReadBlobProviderProps> = ({ children }) => {
    const [imageReadBlobService, setImageReadBlobService] = useState<ReadBlobService | null>(null);

    const imagesContainer = process.env.REACT_APP_IMAGES_CONTAINER as string;

    useEffect(() => {
        const initialize = async () => {
            const imageService = await ReadBlobService.create(imagesContainer, null);
            setImageReadBlobService(imageService);
        }

        initialize();
    }, []);


    return (
        <ReadBlobContext.Provider value={{imageReadBlobService}}>
            {children}
        </ReadBlobContext.Provider>
    );
};

export default ReadBlobProvider;
