import React from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import NavbarAdmin from '../components/Navbar/NavbarAdmin';
import Footer from '../components/Footer/Footer';
import AdminOrders from '../components/Admin/AdminOrders';
import AdminConsignments from '../components/Admin/AdminConsignments';
import AdminUsers from '../components/Admin/AdminUsers';
import AdminDropdownMenus from '../components/Admin/AdminDropdownMenus';
import AdminHomeSuggestions from '../components/Admin/AdminHomeSuggestions';
import AdminPerformerBanners from '../components/Admin/AdminPerformerBanners';
import AdminVenueBanners from '../components/Admin/AdminVenueBanners';
import AdminDeliveryMethods from '../components/Admin/AdminDeliveryMethods';
import AdminPriceAdjustments from '../components/Admin/AdminPriceAdjustments';
import AdminCoupons from '../components/Admin/AdminCoupons';
import '../components/Admin/Admin.css'
import AdminCustomEvents from '../components/Admin/AdminCustomEvents';
import {useAuthenticationContext} from '../providers/AuthenticationProvider';
import LogInFormCard from '../components/LogIn/LogInFormCard';
import AdminGeneralSettings from '../components/Admin/AdminGeneralSettings';
import WriteBlobProvider from "../providers/WriteBlobProvider";

const AdminPage: React.FC = () => {

    const { activeTab = 'orders' } = useParams<{ activeTab: string }>();
    const { user, isLoggedIn, isAuthLoading } = useAuthenticationContext();
    const navigate = useNavigate();

    const handleTabChange = (newTab: string) => {
        navigate(`/admin/${newTab}`);
    }

    const renderActiveTab = () => {
        switch (activeTab) {
            case 'orders':
                return <AdminOrders/>;
            case 'users':
                return <AdminUsers/>;
            case 'consignments':
                return <AdminConsignments/>;
            case 'custom-productions':
                return <AdminCustomEvents/>;

            case 'dropdown-menus':
                return <AdminDropdownMenus/>;
            case 'home-suggestions':
                return <AdminHomeSuggestions/>;
            case 'performer-banners':
                return <AdminPerformerBanners/>;
            case 'venue-banners':
                return <AdminVenueBanners/>;

            case 'general-settings':
                return <AdminGeneralSettings/>;
            case 'delivery-methods':
                return <AdminDeliveryMethods/>;
            case 'price-adjustments':
                return <AdminPriceAdjustments/>;
            case 'coupons':
                return <AdminCoupons/>;

            default:
                return <div className='card' />;
        }
    };


    return (

        <div>
            <NavbarAdmin setActiveTab={handleTabChange}/>
            <div className='container-info'>
                <div className='content-main'>
                    {isAuthLoading ? <div className="loading-spinner"></div>
                        :
                        !isLoggedIn ?
                            <div className='card-top'>
                                <LogInFormCard onClickSignUp={()=> navigate('/signup')} />
                            </div>
                            :
                            user?.isAdmin?
                                <WriteBlobProvider>
                                    {renderActiveTab()}
                                </WriteBlobProvider>
                                :
                            <div className='col card-top'>
                                <div className='text-xl'>Oops... This page does not exist</div>
                                <Link to="/" className='action-button'>
                                    Go Home
                                </Link>
                                <br/>
                            </div>
                        }
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default AdminPage;
