import React, {useCallback, useEffect, useRef, useState} from 'react';
import MarkupGeneral from '../../interfaces/MarkupGeneral';
import MarkupVenue from '../../interfaces/MarkupVenue';
import MarkupEvent from '../../interfaces/MarkupEvent';
import MarkupBase from '../../interfaces/MarkupBase';
import Event from '../../interfaces/Event';
import Venue from '../../interfaces/Venue';
import {
    addEventMarkup,
    addGeneralMarkup,
    addVenueMarkup,
    fetchEventMarkups,
    fetchEventsByQuery,
    fetchGeneralMarkups,
    fetchMarkupSources,
    fetchMarkupTypes,
    fetchVenueMarkups,
    fetchVenuesByQuery,
    removeMarkup
} from '../../api/endpoints';
import {SingleSelectMenu} from '../../utils/DropwdownUtils/DropdownUtils';
import {isRight} from 'fp-ts/lib/Either';


const AdminPriceAdjustments: React.FC = () => {
    const [loadingMarkups, setLoadingMarkups] = useState(true);
    const [eventMarkups, setEventMarkups] = useState<MarkupEvent[]>([]);
    const [venueMarkups, setVenueMarkups] = useState<MarkupVenue[]>([]);
    const [generalMarkups, setGeneralMarkups] = useState<MarkupGeneral[]>([]);
    const [rulesToDelete, setRulesToDelete] = useState<any[]>([]);
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
    const [selectedVenue, setSelectedVenue] = useState<Venue | null>(null);
    const [selectedEventRule, setSelectedEventRule] = useState(-1);
    const [selectedVenueRule, setSelectedVenueRule] = useState(-1);
    const [selectedGeneralRule, setSelectedGeneralRule] = useState(-1);
    const [markupTypes, setMarkupTypes] = useState<string[]>([]);
    const [markupSources, setMarkupSources] = useState<string[]>([]);
    const ruleTypeRef = useRef<HTMLDivElement>(null);
    const [activeRuleTypePicker, setActiveRuleTypePicker] = useState(false);
    const ruleSourceRef = useRef<HTMLDivElement>(null);
    const [activeRuleSourcePicker, setActiveRuleSourcePicker] = useState(false);
    const [isEventFocused, setIsEventFocused] = useState(false);
    const [eventQuery, setEventQuery] = useState('');
    const [eventResults, setEventResults] = useState<Event[] | null>(null);
    const [loadingEventResults, setLoadingEventResults] = useState(false);
    const lastEventSearchedRef = useRef('');
    const eventSearchRef = useRef<HTMLDivElement>(null);
    const [isVenueFocused, setIsVenueFocused] = useState(false);
    const [venueQuery, setVenueQuery] = useState('');
    const [venueResults, setVenueResults] = useState<Venue[] | null>(null);
    const [loadingVenueResults, setLoadingVenueResults] = useState(false);
    const lastVenueSearchedRef = useRef('');
    const venueSearchRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const fetchMarkupInfo = async () => {
            setLoadingMarkups(true);
            const [
                eventsResponseEither,
                venuesResponseEither,
                generalResponseEither,
                typesResponseEither,
                sourcesResponseEither,
            ] = await Promise.all([
                fetchEventMarkups(),
                fetchVenueMarkups(),
                fetchGeneralMarkups(),
                fetchMarkupTypes(),
                fetchMarkupSources(),
            ]);

            if (isRight(eventsResponseEither)) {
                setEventMarkups(eventsResponseEither.right);
            } else {
                console.error(eventsResponseEither.left)
            }
            if (isRight(venuesResponseEither)) {
                setVenueMarkups(venuesResponseEither.right);
            } else {
                console.error(venuesResponseEither.left)
            }
            if (isRight(generalResponseEither)) {
                setGeneralMarkups(generalResponseEither.right);
            } else {
                console.error(generalResponseEither.left)
            }
            if (isRight(typesResponseEither)) {
                setMarkupTypes(typesResponseEither.right);
            } else {
                console.error(typesResponseEither.left)
            }
            if (isRight(sourcesResponseEither)) {
                setMarkupSources(sourcesResponseEither.right);
            } else {
                console.error(sourcesResponseEither.left)
            }

            setLoadingMarkups(false);
        }

        const handleClickOutsideRuleTypePicker = (e: MouseEvent) => {
            if (ruleTypeRef.current && e.target instanceof Node && !ruleTypeRef.current.contains(e.target)) {
                setActiveRuleTypePicker(false);
            }
        };

        const handleClickOutsideRuleSourcePicker = (e: MouseEvent) => {
            if (ruleSourceRef.current && e.target instanceof Node && !ruleSourceRef.current.contains(e.target)) {
                setActiveRuleSourcePicker(false);
            }
        };


        fetchMarkupInfo();

        document.addEventListener('mousedown', handleClickOutsideRuleTypePicker);
        document.addEventListener('mousedown', handleClickOutsideRuleSourcePicker);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideRuleTypePicker);
            document.removeEventListener('mousedown', handleClickOutsideRuleSourcePicker);
        };
    }, []);


    const addEventRule = () => {
        if (selectedEvent) {
            const newBase: MarkupBase = { name: '', source: markupSources[0], lowerBound: 0, upperBound: 99999, markup: 0, markupType: markupTypes[0], minimum: 5, brokerId: '', roundUp: true };
            const newEvent: MarkupEvent = { baseMarkup: newBase, tevoId: selectedEvent.tevoEventId, eventName: selectedEvent.name, eventDate: selectedEvent.occursAtLocal };
            setSelectedEventRule(eventMarkups.length);
            setEventMarkups([...eventMarkups, newEvent]);
            setSelectedEvent(null);
            setSelectedGeneralRule(-1)
            setSelectedVenueRule(-1)
        }
    };

    const addVenueRule = () => {
        if (selectedVenue) {
            const newBase: MarkupBase = { name: '', source: markupSources[0], lowerBound: 0, upperBound: 99999, markup: 0, markupType: markupTypes[0], minimum: 5, brokerId: '', roundUp: true };
            const newVenue: MarkupVenue = { baseMarkup: newBase, tevoId: selectedVenue.tevoVenueId, venueName: selectedVenue.name };
            setSelectedVenueRule(venueMarkups.length);
            setVenueMarkups([...venueMarkups, newVenue]);
            setSelectedVenue(null);
            setSelectedEventRule(-1)
            setSelectedGeneralRule(-1)
        }
    };

    const addGeneralRule = () => {
        const newBase: MarkupBase = { name: '', source: markupSources[0], lowerBound: 0, upperBound: 99999, markup: 0, markupType: markupTypes[0], minimum: 5, brokerId: '', roundUp: true };
        const newGeneral: MarkupGeneral = { baseMarkup: newBase };
        setSelectedGeneralRule(generalMarkups.length);
        setGeneralMarkups([...generalMarkups, newGeneral]);
        setSelectedVenueRule(-1);
        setSelectedEventRule(-1);
    };


    const handleMarkupNameChange = (type: string, markupIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        if (type == "Event") {
            const updatedEventMarkups = [...eventMarkups];
            updatedEventMarkups[markupIndex].baseMarkup.name = e.target.value;
            setEventMarkups(updatedEventMarkups);
        }

        if (type == "Venue") {
            const updatedVenueMarkups = [...venueMarkups];
            updatedVenueMarkups[markupIndex].baseMarkup.name = e.target.value;
            setVenueMarkups(updatedVenueMarkups);
        }

        if (type == "General") {
            const updatedGeneralMarkups = [...generalMarkups];
            updatedGeneralMarkups[markupIndex].baseMarkup.name = e.target.value;
            setGeneralMarkups(updatedGeneralMarkups);
        }
    };


    const handleMarkupLowerChange = (type: string, markupIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        if (type == "Event") {
            const updatedEventMarkups = [...eventMarkups];
            updatedEventMarkups[markupIndex].baseMarkup.lowerBound = parseFloat(e.target.value);
            setEventMarkups(updatedEventMarkups);
        }

        if (type == "Venue") {
            const updatedVenueMarkups = [...venueMarkups];
            updatedVenueMarkups[markupIndex].baseMarkup.lowerBound = parseFloat(e.target.value);
            setVenueMarkups(updatedVenueMarkups);
        }

        if (type == "General") {
            const updatedGeneralMarkups = [...generalMarkups];
            updatedGeneralMarkups[markupIndex].baseMarkup.lowerBound = parseFloat(e.target.value);
            setGeneralMarkups(updatedGeneralMarkups);
        }
    };

    const handleMarkupUpperChange = (type: string, markupIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        if (type == "Event") {
            const updatedEventMarkups = [...eventMarkups];
            updatedEventMarkups[markupIndex].baseMarkup.upperBound = parseFloat(e.target.value);
            setEventMarkups(updatedEventMarkups);
        }

        if (type == "Venue") {
            const updatedVenueMarkups = [...venueMarkups];
            updatedVenueMarkups[markupIndex].baseMarkup.upperBound = parseFloat(e.target.value);
            setVenueMarkups(updatedVenueMarkups);
        }

        if (type == "General") {
            const updatedGeneralMarkups = [...generalMarkups];
            updatedGeneralMarkups[markupIndex].baseMarkup.upperBound = parseFloat(e.target.value);
            setGeneralMarkups(updatedGeneralMarkups);
        }
    };


    const handleMarkupAmountChange = (type: string, markupIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        if (type == "Event") {
            const updatedEventMarkups = [...eventMarkups];
            updatedEventMarkups[markupIndex].baseMarkup.markup = parseFloat(e.target.value);
            setEventMarkups(updatedEventMarkups);
        }

        if (type == "Venue") {
            const updatedVenueMarkups = [...venueMarkups];
            updatedVenueMarkups[markupIndex].baseMarkup.markup = parseFloat(e.target.value);
            setVenueMarkups(updatedVenueMarkups);
        }

        if (type == "General") {
            const updatedGeneralMarkups = [...generalMarkups];
            updatedGeneralMarkups[markupIndex].baseMarkup.markup = parseFloat(e.target.value);
            setGeneralMarkups(updatedGeneralMarkups);
        }
    };


    const handleMarkupMinChange = (type: string, markupIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        if (type == "Event") {
            const updatedEventMarkups = [...eventMarkups];
            updatedEventMarkups[markupIndex].baseMarkup.minimum = parseFloat(e.target.value);
            setEventMarkups(updatedEventMarkups);
        }

        if (type == "Venue") {
            const updatedVenueMarkups = [...venueMarkups];
            updatedVenueMarkups[markupIndex].baseMarkup.minimum = parseFloat(e.target.value);
            setVenueMarkups(updatedVenueMarkups);
        }

        if (type == "General") {
            const updatedGeneralMarkups = [...generalMarkups];
            updatedGeneralMarkups[markupIndex].baseMarkup.minimum = parseFloat(e.target.value);
            setGeneralMarkups(updatedGeneralMarkups);
        }
    };

    const handleMarkupBrokerIdChange = (type: string, markupIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        if (type == "Event") {
            const updatedEventMarkups = [...eventMarkups];
            updatedEventMarkups[markupIndex].baseMarkup.brokerId = e.target.value;
            setEventMarkups(updatedEventMarkups);
        }

        if (type == "Venue") {
            const updatedVenueMarkups = [...venueMarkups];
            updatedVenueMarkups[markupIndex].baseMarkup.brokerId = e.target.value;
            setVenueMarkups(updatedVenueMarkups);
        }

        if (type == "General") {
            const updatedGeneralMarkups = [...generalMarkups];
            updatedGeneralMarkups[markupIndex].baseMarkup.brokerId = e.target.value;
            setGeneralMarkups(updatedGeneralMarkups);
        }
    };


    const handleMarkupRoundUpChange = (type: string, markupIndex: number, roundUp: boolean) => {
        if (type == "Event") {
            const updatedEventMarkups = [...eventMarkups];
            updatedEventMarkups[markupIndex].baseMarkup.roundUp = roundUp;
            setEventMarkups(updatedEventMarkups);
        }

        if (type == "Venue") {
            const updatedVenueMarkups = [...venueMarkups];
            updatedVenueMarkups[markupIndex].baseMarkup.roundUp = roundUp;
            setVenueMarkups(updatedVenueMarkups);
        }

        if (type == "General") {
            const updatedGeneralMarkups = [...generalMarkups];
            updatedGeneralMarkups[markupIndex].baseMarkup.roundUp = roundUp;
            setGeneralMarkups(updatedGeneralMarkups);
        }
    }

    const setSelectedRuleType = (type: string, markupIndex: number) => (selectedOption: string) => {

        if (type == "Event") {
            const updatedEventMarkups = [...eventMarkups];
            updatedEventMarkups[markupIndex].baseMarkup.markupType = selectedOption;
            setEventMarkups(updatedEventMarkups);
        }

        if (type == "Venue") {
            const updatedVenueMarkups = [...venueMarkups];
            updatedVenueMarkups[markupIndex].baseMarkup.markupType = selectedOption;
            setVenueMarkups(updatedVenueMarkups);
        }

        if (type == "General") {
            const updatedGeneralMarkups = [...generalMarkups];
            updatedGeneralMarkups[markupIndex].baseMarkup.markupType = selectedOption;
            setGeneralMarkups(updatedGeneralMarkups);
        }
    };


    const createRuleTypeChangeHandler = (type: string, markupIndex: number): React.Dispatch<React.SetStateAction<string>> => {

        if (type == "Event") {
            return (newSelectedOption: string | ((prevState: string) => string)) => {
                if (typeof newSelectedOption === 'function') {
                    newSelectedOption = newSelectedOption(eventMarkups[markupIndex].baseMarkup.markupType);
                }
                setSelectedRuleType(type, markupIndex)(newSelectedOption);
            };
        }

        else if (type == "Venue") {
            return (newSelectedOption: string | ((prevState: string) => string)) => {
                if (typeof newSelectedOption === 'function') {
                    newSelectedOption = newSelectedOption(venueMarkups[markupIndex].baseMarkup.markupType);
                }
                setSelectedRuleType(type, markupIndex)(newSelectedOption);
            };
        }

        else {
            return (newSelectedOption: string | ((prevState: string) => string)) => {
                if (typeof newSelectedOption === 'function') {
                    newSelectedOption = newSelectedOption(generalMarkups[markupIndex].baseMarkup.markupType);
                }
                setSelectedRuleType(type, markupIndex)(newSelectedOption);
            };
        }

    };

    const setSelectedRuleSource = (type: string, markupIndex: number) => (selectedOption: string) => {

        if (type == "Event") {
            const updatedEventMarkups = [...eventMarkups];
            updatedEventMarkups[markupIndex].baseMarkup.source = selectedOption;
            setEventMarkups(updatedEventMarkups);
        }

        if (type == "Venue") {
            const updatedVenueMarkups = [...venueMarkups];
            updatedVenueMarkups[markupIndex].baseMarkup.source = selectedOption;
            setVenueMarkups(updatedVenueMarkups);
        }

        if (type == "General") {
            const updatedGeneralMarkups = [...generalMarkups];
            updatedGeneralMarkups[markupIndex].baseMarkup.source = selectedOption;
            setGeneralMarkups(updatedGeneralMarkups);
        }
    };


    const createRuleSourceChangeHandler = (type: string, markupIndex: number): React.Dispatch<React.SetStateAction<string>> => {

        if (type == "Event") {
            return (newSelectedOption: string | ((prevState: string) => string)) => {
                if (typeof newSelectedOption === 'function') {
                    newSelectedOption = newSelectedOption(eventMarkups[markupIndex].baseMarkup.source);
                }
                setSelectedRuleSource(type, markupIndex)(newSelectedOption);
            };
        }

        else if (type == "Venue") {
            return (newSelectedOption: string | ((prevState: string) => string)) => {
                if (typeof newSelectedOption === 'function') {
                    newSelectedOption = newSelectedOption(venueMarkups[markupIndex].baseMarkup.source);
                }
                setSelectedRuleSource(type, markupIndex)(newSelectedOption);
            };
        }

        else {
            return (newSelectedOption: string | ((prevState: string) => string)) => {
                if (typeof newSelectedOption === 'function') {
                    newSelectedOption = newSelectedOption(generalMarkups[markupIndex].baseMarkup.source);
                }
                setSelectedRuleSource(type, markupIndex)(newSelectedOption);
            };
        }

    };

    const removeRule = (type: string, markupIndex: number) => {

        if (type == "Event") {
            setRulesToDelete([...rulesToDelete, eventMarkups[markupIndex]])

            const updatedEventMarkups = eventMarkups.filter((_, index) => index !== markupIndex);
            setEventMarkups(updatedEventMarkups);
            setSelectedEventRule(-1)
        }

        if (type == "Venue") {
            setRulesToDelete([...rulesToDelete, venueMarkups[markupIndex]])

            const updatedVenueMarkups = venueMarkups.filter((_, index) => index !== markupIndex);
            setVenueMarkups(updatedVenueMarkups);
            setSelectedVenueRule(-1)
        }

        if (type == "General") {
            setRulesToDelete([...rulesToDelete, generalMarkups[markupIndex]])

            const updatedGeneralMarkups = generalMarkups.filter((_, index) => index !== markupIndex);
            setGeneralMarkups(updatedGeneralMarkups);
            setSelectedGeneralRule(-1)
        }
    };

    const handleSelectedEvent = (result: Event) => {
        setIsEventFocused(false);
        setEventQuery('');
        setSelectedEvent(result)
    }

    const handleSelectedVenue = (result: Venue) => {
        setIsVenueFocused(false);
        setVenueQuery('');
        setSelectedVenue(result)
    }

    const handleEventRuleSelect = (markupIndex: number) => {
        if (selectedEventRule === markupIndex) {
            setSelectedEventRule(-1)
        } else {
            setSelectedEventRule(markupIndex);
            setSelectedGeneralRule(-1)
            setSelectedVenueRule(-1)
        }
    }

    const handleVenueRuleSelect = (markupIndex: number) => {
        if (selectedVenueRule === markupIndex) {
            setSelectedVenueRule(-1)
        } else {
            setSelectedVenueRule(markupIndex);
            setSelectedEventRule(-1);
            setSelectedGeneralRule(-1);
        }
    }

    const handleGeneralRuleSelect = (markupIndex: number) => {
        if (selectedGeneralRule === markupIndex) {
            setSelectedGeneralRule(-1)
        } else {
            setSelectedGeneralRule(markupIndex);
            setSelectedEventRule(-1);
            setSelectedVenueRule(-1)
        }
    }

    const handleEventQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEventQuery(e.target.value);
    };

    const handleVenueQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVenueQuery(e.target.value);
    };


    const fetchEventResults = async (query: string) => {
        if (query === '') {
            setEventResults(null);
            return;
        }
        setLoadingEventResults(true);
        lastEventSearchedRef.current = query;

        const resultResponseEither = await fetchEventsByQuery(query, false, 1)
        if (isRight(resultResponseEither)) {
            setEventResults(resultResponseEither.right);
        } else {
            console.error(resultResponseEither.left)
        }
        setLoadingEventResults(false);
    }

    const debounceFetchEventResults = useCallback(
        (() => {
            let timeoutId: NodeJS.Timeout;
            return (searchQuery: string) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    fetchEventResults(searchQuery);
                }, 300);
            };
        })(),
        []
    );

    useEffect(() => {
        debounceFetchEventResults(eventQuery);
    }, [eventQuery, debounceFetchEventResults]);


    const fetchVenueResults = async (query: string) => {
        if (query === '') {
            setVenueResults(null);
            return;
        }
        setLoadingVenueResults(true);
        lastVenueSearchedRef.current = query;

        const resultResponseEither = await fetchVenuesByQuery(query)
        if (isRight(resultResponseEither)) {
            setVenueResults(resultResponseEither.right);
        } else {
            console.error(resultResponseEither.left)
        }
        setLoadingVenueResults(false);
    }

    const debounceFetchVenueResults = useCallback(
        (() => {
            let timeoutId: NodeJS.Timeout;
            return (searchQuery: string) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    fetchVenueResults(searchQuery);
                }, 300);
            };
        })(),
        []
    );

    useEffect(() => {
        debounceFetchVenueResults(venueQuery);
    }, [venueQuery, debounceFetchVenueResults]);


    const handleSubmitChanges = async () => {
        setLoadingMarkups(true);

        for (let i = 0; i < rulesToDelete.length; i++) {
            if (rulesToDelete[i].baseMarkup.id !== undefined) {
                const deleteMarkupResponseEither = await removeMarkup(rulesToDelete[i].baseMarkup.id as number);
                if (isRight(deleteMarkupResponseEither)) {
                } else {
                    console.error(deleteMarkupResponseEither.left)
                }
            }
        }


        for (let i = 0; i < eventMarkups.length; i++) {
            if (eventMarkups[i].baseMarkup.name == '' || eventMarkups[i].baseMarkup.markup == 0) {
                continue
            }
            const setEventMarkupResponseEither = await addEventMarkup(eventMarkups[i]);
            if (isRight(setEventMarkupResponseEither)) {
            } else {
                console.error(setEventMarkupResponseEither.left)
            }
        }

        for (let i = 0; i < venueMarkups.length; i++) {
            if (venueMarkups[i].baseMarkup.name == '' || venueMarkups[i].baseMarkup.markup == 0) {
                continue
            }
            const setVenueMarkupResponseEither = await addVenueMarkup(venueMarkups[i]);
            if (isRight(setVenueMarkupResponseEither)) {
            } else {
                console.error(setVenueMarkupResponseEither.left)
            }
        }

        for (let i = 0; i < generalMarkups.length; i++) {
            if (generalMarkups[i].baseMarkup.name == '') {
                continue
            }
            const setGeneralMarkupResponseEither = await addGeneralMarkup(generalMarkups[i]);
            if (isRight(setGeneralMarkupResponseEither)) {
            } else {
                console.error(setGeneralMarkupResponseEither.left)
            }
        }
        setLoadingMarkups(false);
    }

    return (
        <div className='card card-top' >
            <div className='row'>
                <div className='left'>
                    <div className='text-l'>Price Adjustments</div>
                </div>
                <button className='admin-action-button' onClick={handleSubmitChanges}>Save Changes</button>
            </div>

            {loadingMarkups ?
                <div className="loading-spinner" />
                :
                <>
                    <div className='card-item'>
                        <div className='row'>
                            <div className='text-s-bold'>
                                General Markups
                            </div>
                        </div>
                        <div className='card-item'>
                            <button className='admin-button' onClick={addGeneralRule}>Add General Rule</button>
                        </div>
                        {generalMarkups.map((markup, markupIndex) => {
                            const base = markup.baseMarkup;
                            const type = "General"
                            return (
                                <div className='card-item'>
                                    <div className='card-list gray-bg'>

                                        <div className='row'>
                                            <div className='left'>
                                                <div className='text-xs'>Name:&nbsp;</div>
                                                <input type="text" className='admin-input' value={base.name} onChange={handleMarkupNameChange(type, markupIndex)} placeholder="Type Rule Name..." />
                                                <div className='gap-s' />
                                                <div className='text-xs'>Amount:&nbsp;</div>
                                                <input className='admin-input' type="number" value={base.markup} onChange={handleMarkupAmountChange(type, markupIndex)} placeholder="Markup Amount" />
                                            </div>
                                            <button className='admin-button' onClick={() => handleGeneralRuleSelect(markupIndex)}>{selectedGeneralRule === markupIndex ? "Collapse" : "Expand"}</button>
                                            <button className='admin-delete-button' onClick={() => removeRule(type, markupIndex)}>Delete</button>
                                        </div>

                                        {selectedGeneralRule === markupIndex &&
                                            <div className='card-item'>
                                                <div className='row'>
                                                    <div className='text-xs gap-xl'>Rule Source:&nbsp;</div>
                                                    <div className='relative' ref={ruleSourceRef}>
                                                        <button className='admin-button' onClick={(() => setActiveRuleSourcePicker(!activeRuleSourcePicker))}>{base.source}</button>
                                                        {activeRuleSourcePicker &&
                                                            <SingleSelectMenu
                                                                options={markupSources}
                                                                selectedOption={base.source}
                                                                setSelectedOption={createRuleSourceChangeHandler(type, markupIndex)}
                                                                closeMenu={() => setActiveRuleSourcePicker(false)}
                                                                mustSelect={true}
                                                                rightAligned={false}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='text-xs gap-xl'>Rule Type:&nbsp;</div>
                                                    <div className='relative' ref={ruleTypeRef}>
                                                        <button className='admin-button' onClick={(() => setActiveRuleTypePicker(!activeRuleTypePicker))}>{base.markupType}</button>
                                                        {activeRuleTypePicker &&
                                                            <SingleSelectMenu
                                                                options={markupTypes}
                                                                selectedOption={base.markupType}
                                                                setSelectedOption={createRuleTypeChangeHandler(type, markupIndex)}
                                                                closeMenu={() => setActiveRuleTypePicker(false)}
                                                                mustSelect={true}
                                                                rightAligned={false}
                                                            />
                                                        }
                                                    </div>
                                                    <div className='gap-s' />
                                                    <div className='text-xs'>Round Up:&nbsp;</div>
                                                    <input type="checkbox" checked={base.roundUp} onChange={() => handleMarkupRoundUpChange(type, markupIndex, !base.roundUp)} className='admin-checkbox' />
                                                </div>

                                                <div className='row'>
                                                    <div className='text-xs gap-xl'>Brokerage Id:&nbsp;</div>
                                                    <input className='admin-input' type="text" value={base.brokerId} onChange={handleMarkupBrokerIdChange(type, markupIndex)} placeholder="Empty = all" />

                                                </div>
                                                <div className='row'>
                                                    <div className='text-xs gap-xl'>Min Increase:&nbsp;</div>
                                                    <input className='admin-input' type="number" value={base.minimum} onChange={handleMarkupMinChange(type, markupIndex)} placeholder="Minimum Total Price" />

                                                </div>
                                                <div className='row'>
                                                    <div className='text-xs gap-xl'>Lower Bound:&nbsp;</div>
                                                    <input className='admin-input' type="number" value={base.lowerBound} onChange={handleMarkupLowerChange(type, markupIndex)} placeholder="Lower Bound" />
                                                    <div className='gap-s' />
                                                    <div className='text-xs'>Upper Bound:&nbsp;</div>
                                                    <input className='admin-input' type="number" value={base.upperBound} onChange={handleMarkupUpperChange(type, markupIndex)} placeholder="Upper Bound" />
                                                </div>

                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        }
                        )}
                    </div>


                    <div className='card-item'>
                        <div className='row'>
                            <div className='text-s-bold'>
                                Venue Markups
                            </div>
                        </div>
                        <div ref={venueSearchRef}>
                            <div className='card-item'>
                                <input
                                    type="text"
                                    value={venueQuery}
                                    onFocus={() => setIsVenueFocused(true)}
                                    onChange={handleVenueQueryChange}
                                    placeholder={`Search for the name of a venue to select`}
                                    className='admin-search'
                                />
                            </div>
                            {(venueResults !== null && isVenueFocused) &&
                                <div className='relative'>
                                    <div className='input-search-results'>
                                        {venueResults.map(result => (
                                            <div className="menu-item" onClick={() => handleSelectedVenue(result)}>
                                                <div className='menu-item-title'>{result.name}</div>
                                            </div>))}
                                    </div>
                                </div>
                            }
                        </div>
                        {selectedVenue &&
                            <div className='text-xs'>{"Selected: " + selectedVenue.name}</div>
                        }
                        <button className='admin-button' onClick={addVenueRule}>Add Venue Rule</button>
                        <hr />
                        {venueMarkups.map((markup, markupIndex) => {
                            const base = markup.baseMarkup;
                            const type = "Venue"
                            return (
                                <div className='card-item'>
                                    <div className='card-list gray-bg'>

                                        <div className='row'>
                                            <div className='left'>
                                                <div className='text-xs'>Name:&nbsp;</div>
                                                <input className='admin-input' type="text" value={base.name} onChange={handleMarkupNameChange(type, markupIndex)} placeholder="Type Rule Name..." />
                                                <div className='gap-s' />
                                                <div className='text-xs'>Amount:&nbsp;</div>
                                                <input className='admin-input' type="number" value={base.markup} onChange={handleMarkupAmountChange(type, markupIndex)} placeholder="Markup Amount" />
                                                <div className='gap-s' />
                                                <div className='text-xs'>{"Venue: " + markup.venueName}</div>
                                            </div>
                                            <button className='admin-button' onClick={() => handleVenueRuleSelect(markupIndex)}>{selectedVenueRule === markupIndex ? "Collapse" : "Expand"}</button>
                                            <button className='admin-delete-button' onClick={() => removeRule(type, markupIndex)}>Delete</button>
                                        </div>

                                        {selectedVenueRule === markupIndex &&
                                            <div className='card-item'>
                                                <div className='row'>
                                                    <div className='text-xs gap-xl'>Rule Source:&nbsp;</div>
                                                    <div className='relative' ref={ruleSourceRef}>
                                                        <button className='admin-button' onClick={(() => setActiveRuleSourcePicker(!activeRuleSourcePicker))}>{base.source}</button>
                                                        {activeRuleSourcePicker &&
                                                            <SingleSelectMenu
                                                                options={markupSources}
                                                                selectedOption={base.source}
                                                                setSelectedOption={createRuleSourceChangeHandler(type, markupIndex)}
                                                                closeMenu={() => setActiveRuleSourcePicker(false)}
                                                                mustSelect={true}
                                                                rightAligned={false}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='text-xs gap-xl'>Rule Type:&nbsp;</div>
                                                    <div className='relative' ref={ruleTypeRef}>
                                                        <button className='admin-button' onClick={(() => setActiveRuleTypePicker(!activeRuleTypePicker))}>{base.markupType}</button>
                                                        {activeRuleTypePicker &&
                                                            <SingleSelectMenu
                                                                options={markupTypes}
                                                                selectedOption={base.markupType}
                                                                setSelectedOption={createRuleTypeChangeHandler(type, markupIndex)}
                                                                closeMenu={() => setActiveRuleTypePicker(false)}
                                                                mustSelect={true}
                                                                rightAligned={false}
                                                            />
                                                        }
                                                    </div>
                                                    <div className='gap-s' />
                                                    <div className='text-xs'>Round Up:&nbsp;</div>
                                                    <input type="checkbox" checked={base.roundUp} onChange={() => handleMarkupRoundUpChange(type, markupIndex, !base.roundUp)} className='admin-checkbox' />
                                                </div>

                                                <div className='row'>
                                                    <div className='text-xs gap-xl'>Brokerage Id:&nbsp;</div>
                                                    <input className='admin-input' type="text" value={base.brokerId} onChange={handleMarkupBrokerIdChange(type, markupIndex)} placeholder="Empty = all" />

                                                </div>
                                                <div className='row'>
                                                    <div className='text-xs gap-xl'>Min Increase:&nbsp;</div>
                                                    <input className='admin-input' type="number" value={base.minimum} onChange={handleMarkupMinChange(type, markupIndex)} placeholder="Minimum Total Price" />

                                                </div>
                                                <div className='row'>
                                                    <div className='text-xs gap-xl'>Lower Bound:&nbsp;</div>
                                                    <input className='admin-input' type="number" value={base.lowerBound} onChange={handleMarkupLowerChange(type, markupIndex)} placeholder="Lower Bound" />
                                                    <div className='gap-s' />
                                                    <div className='text-xs'>Upper Bound:&nbsp;</div>
                                                    <input className='admin-input' type="number" value={base.upperBound} onChange={handleMarkupUpperChange(type, markupIndex)} placeholder="Upper Bound" />
                                                </div>

                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        }

                        )}
                    </div>


                    <div className='card-item'>
                        <div className='row'>
                            <div className='text-s-bold'>
                                Event Markups
                            </div>
                        </div>
                        <div ref={eventSearchRef}>
                            <div className='card-item'>
                                <input
                                    type="text"
                                    value={eventQuery}
                                    onFocus={() => setIsEventFocused(true)}
                                    onChange={handleEventQueryChange}
                                    placeholder={`Search for the name of an event to select`}
                                    className='admin-search'
                                />
                            </div>
                            {(eventResults !== null && isEventFocused) &&
                                <div className='relative'>
                                    <div className='input-search-results'>
                                        {eventResults.map(result => (
                                            <div className="menu-item" onClick={() => handleSelectedEvent(result)}>
                                                <div className='menu-item-title'>{result.name}</div>
                                                <div className='menu-item-title'>{result.occursAtLocal}</div>
                                            </div>))}
                                    </div>
                                </div>
                            }
                        </div>
                        {selectedEvent &&
                            <div className='text-xs'>{"Selected: " + selectedEvent.name + " - " + selectedEvent.occursAtLocal}</div>
                        }
                        <button className='admin-button' onClick={addEventRule}>Add Event Rule</button>
                        <hr />
                        {eventMarkups.map((markup, markupIndex) => {
                            const base = markup.baseMarkup;
                            const type = "Event"
                            return (
                                <div className='card-item'>
                                    <div className='card-list gray-bg'>

                                        <div className='row'>
                                            <div className='left'>
                                                <div className='text-xs'>Name:&nbsp;</div>
                                                <input className='admin-input' type="text" value={base.name} onChange={handleMarkupNameChange(type, markupIndex)} placeholder="Type Rule Name..." />
                                                <div className='gap-s' />
                                                <div className='text-xs'>Amount:&nbsp;</div>
                                                <input className='admin-input' type="number" value={base.markup} onChange={handleMarkupAmountChange(type, markupIndex)} placeholder="Markup Amount" />
                                                <div className='gap-s' />
                                                <div className='text-xs'>{"Event: " + markup.eventName}</div>
                                            </div>
                                            <button className='admin-button' onClick={() => handleEventRuleSelect(markupIndex)}>{selectedEventRule === markupIndex ? "Collapse" : "Expand"}</button>
                                            <button className='admin-delete-button' onClick={() => removeRule(type, markupIndex)}>Delete</button>

                                        </div>

                                        {selectedEventRule === markupIndex &&
                                            <div className='card-item'>
                                                <div className='row'>
                                                    <div className='text-xs gap-xl'>Rule Source:&nbsp;</div>
                                                    <div className='relative' ref={ruleSourceRef}>
                                                        <button className='admin-button' onClick={(() => setActiveRuleSourcePicker(!activeRuleSourcePicker))}>{base.source}</button>
                                                        {activeRuleSourcePicker &&
                                                            <SingleSelectMenu
                                                                options={markupSources}
                                                                selectedOption={base.source}
                                                                setSelectedOption={createRuleSourceChangeHandler(type, markupIndex)}
                                                                closeMenu={() => setActiveRuleSourcePicker(false)}
                                                                mustSelect={true}
                                                                rightAligned={false}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='text-xs gap-xl'>Rule Type:&nbsp;</div>
                                                    <div className='relative' ref={ruleTypeRef}>
                                                        <button className='admin-button' onClick={(() => setActiveRuleTypePicker(!activeRuleTypePicker))}>{base.markupType}</button>
                                                        {activeRuleTypePicker &&
                                                            <SingleSelectMenu
                                                                options={markupTypes}
                                                                selectedOption={base.markupType}
                                                                setSelectedOption={createRuleTypeChangeHandler(type, markupIndex)}
                                                                closeMenu={() => setActiveRuleTypePicker(false)}
                                                                mustSelect={true}
                                                                rightAligned={false}
                                                            />
                                                        }
                                                    </div>
                                                    <div className='gap-s' />
                                                    <div className='text-xs'>Round Up:&nbsp;</div>
                                                    <input type="checkbox" checked={base.roundUp} onChange={() => handleMarkupRoundUpChange(type, markupIndex, !base.roundUp)} className='admin-checkbox' />
                                                </div>

                                                <div className='row'>
                                                    <div className='text-xs gap-xl'>Brokerage Id:&nbsp;</div>
                                                    <input className='admin-input' type="text" value={base.brokerId} onChange={handleMarkupBrokerIdChange(type, markupIndex)} placeholder="Empty = all" />

                                                </div>
                                                <div className='row'>
                                                    <div className='text-xs gap-xl'>Min Increase:&nbsp;</div>
                                                    <input className='admin-input' type="number" value={base.minimum} onChange={handleMarkupMinChange(type, markupIndex)} placeholder="Minimum Total Price" />

                                                </div>
                                                <div className='row'>
                                                    <div className='text-xs gap-xl'>Lower Bound:&nbsp;</div>
                                                    <input className='admin-input' type="number" value={base.lowerBound} onChange={handleMarkupLowerChange(type, markupIndex)} placeholder="Lower Bound" />
                                                    <div className='gap-s' />
                                                    <div className='text-xs'>Upper Bound:&nbsp;</div>
                                                    <input className='admin-input' type="number" value={base.upperBound} onChange={handleMarkupUpperChange(type, markupIndex)} placeholder="Upper Bound" />
                                                </div>

                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </>
            }
        </div>
    )
}

export default AdminPriceAdjustments;
