import React, {useEffect, useRef, useState} from 'react';
import Event from '../../interfaces/Event';
import './Events.css';
import 'react-datepicker/dist/react-datepicker.css';
import {DateRangeMenu, MultiSelectMenu, SingleSelectMenu} from '../../utils/DropwdownUtils/DropdownUtils';
import Performer from '../../interfaces/Performer';
import {formatDateObjToMonthAndDay} from '../../utils/DateUtils/DateUtils';
import {useScreenSizeContext} from '../../providers/ScreenSizeProvider';
import {FaTimes} from 'react-icons/fa';
import EventBar from './EventBar';
import {isRight} from 'fp-ts/lib/Either';
import {fetchEventsByPerformer} from "../../api/endpoints";


interface PerformerEventsProps {
    performer: Performer | null;
    loadingPerformer: boolean;
    initialEvents: Event[];
    loadingEvents: boolean;
}

const PerformerEvents: React.FC<PerformerEventsProps> = ({ performer, loadingPerformer, initialEvents, loadingEvents }) => {
    const datePickerRef = useRef<HTMLDivElement>(null);
    const homeAwayPickerRef = useRef<HTMLDivElement>(null);
    const cityPickerRef = useRef<HTMLDivElement>(null);
    const citiesSet = new Set(initialEvents.map(event => event.venue.location))

    const pageNumber = useRef<number>(1);
    const [allEvents, setAllEvents] = useState<Event[]>(initialEvents);
    const [loadingMoreEvents, setLoadingMoreEvents] = useState<boolean>(false);
    const [noMoreEvents, setNoMoreEvents] = useState<boolean>(false);
    const [eventsToShow, setEventsToShow] = useState<Event[]>(initialEvents);
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
    const [selectedCities, setSelectedCities] = useState<Set<string>>(new Set());
    const [selectedHomeAway, setSelectedHomeAway] = useState('');
    const [activeDatePicker, setActiveDatePicker] = useState(false);
    const [activeCityPicker, setActiveCityPicker] = useState(false);
    const [activeHomeAwayPicker, setActiveHomeAwayPicker] = useState(false);

    const { isMobile } = useScreenSizeContext();

    useEffect(() => {
        setNoMoreEvents(false); // reset for new searches from top bar
        pageNumber.current = 1; // reset for new searches from top bar
        setAllEvents(initialEvents);
        handleResetFilters(); // reset for new searches from top bar
    }, [initialEvents]);


    useEffect(() => {
        const firstDate: Date | null = dateRange[0]
        const secondDate: Date | null = dateRange[1]
        let adjustedEnd = secondDate ? new Date(secondDate) : null;
        if (adjustedEnd) {
            adjustedEnd.setDate(adjustedEnd.getDate() + 1);
        }
        const filteredEvents = allEvents.filter(event => {
            const withinSelectedCities = selectedCities.size == 0 || selectedCities.has(event.venue.location);
            const withinHomeAway = selectedHomeAway == '' || (selectedHomeAway == 'Home Games' && event.venue.tevoVenueId == performer?.venue?.tevoVenueId) || (selectedHomeAway == 'Away Games' && event.venue.tevoVenueId !== performer?.venue?.tevoVenueId)
            if (!event.occursAtLocal) {
                return withinSelectedCities && withinHomeAway;
            }
            const eventDate = new Date(event.occursAtLocal);
            const withinDateRange = (firstDate !== null && adjustedEnd !== null) ? eventDate >= firstDate && eventDate <= adjustedEnd : true;
            return withinDateRange && withinSelectedCities && withinHomeAway
        })
        setEventsToShow(filteredEvents);

    }, [allEvents, selectedCities, dateRange, selectedHomeAway, performer])


    useEffect(() => {
        const handleClickOutsideDatePicker = (e: MouseEvent) => {
            if (datePickerRef.current && e.target instanceof Node && !datePickerRef.current.contains(e.target)) {
                setActiveDatePicker(false);
            }
        };

        const handleClickOutsideCityPicker = (e: MouseEvent) => {
            if (cityPickerRef.current && e.target instanceof Node && !cityPickerRef.current.contains(e.target)) {
                setActiveCityPicker(false);
            }
        };

        const handleClickOutsideHomeAwayPicker = (e: MouseEvent) => {
            if (homeAwayPickerRef.current && e.target instanceof Node && !homeAwayPickerRef.current.contains(e.target)) {
                setActiveHomeAwayPicker(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideDatePicker);
        document.addEventListener('mousedown', handleClickOutsideCityPicker);
        document.addEventListener('mousedown', handleClickOutsideHomeAwayPicker);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideDatePicker);
            document.removeEventListener('mousedown', handleClickOutsideCityPicker);
            document.removeEventListener('mousedown', handleClickOutsideHomeAwayPicker);
        };
    }, []);


    const showMoreEvents = async () => {
        if (!loadingMoreEvents && performer) {
            setLoadingMoreEvents(true);
            const eventsPageDataEither = await fetchEventsByPerformer(performer.tevoPerformerId.toString(), pageNumber.current + 1);
            if (isRight(eventsPageDataEither)) {
                if (eventsPageDataEither.right.length > 0) {
                    setAllEvents(prevEvents => [...prevEvents, ...eventsPageDataEither.right]);
                    pageNumber.current += 1;
                    if (pageNumber.current > 4) {
                        setNoMoreEvents(true); // prevent autoloading too many pages, especially when filtering (max: 5 x 100)
                    }
                } else {
                    setNoMoreEvents(true);
                }
            }
            setLoadingMoreEvents(false);
        }
    };

    const buttonRef = useRef(null); // ref for "Show More" button
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !loadingMoreEvents && !noMoreEvents) {
                    // trigger when the button is in view
                    showMoreEvents();
                }
            },
            { threshold: 1.0 } // fully visible in the viewport
        );

        // Observe the "Show More" button
        if (buttonRef.current) {
            observer.observe(buttonRef.current);
        }

        // Cleanup on unmount
        return () => {
            if (buttonRef.current) {
                observer.unobserve(buttonRef.current);
            }
        };
    }, [loadingMoreEvents, noMoreEvents, showMoreEvents]);

    const handleResetFilters = () => {
        setDateRange([null, null]);
        setSelectedHomeAway('');
        setSelectedCities(new Set());
    }

    const dateFiltered = dateRange[1] !== null;
    const homeAwayFiltered = selectedHomeAway !== '';
    const citiesFiltered = selectedCities.size !== 0;
    const dateRangeString = (dateRange[0] && dateRange[1]) ? formatDateObjToMonthAndDay(dateRange[0]) + " - " + formatDateObjToMonthAndDay(dateRange[1]) : '';

    return (
        <div className='container-main'>
            <div className='content-main'>
                <div className={`${isMobile ? "col" : "row"} `}>
                    <div className='row'>
                        <div className={`${isMobile ? "left text-m" : "left text-xl"} `}>Upcoming Events</div>
                    </div>
                    <br/>
                    {initialEvents.length > 0 &&
                        <div className={`${isMobile ? "row" : 'row justify-end'} `}>
                            <div className='relative' ref={datePickerRef}>
                                <button className={`filter-button ${dateFiltered && 'filtered'}`}
                                        onClick={(() => setActiveDatePicker(!activeDatePicker))}>{(dateFiltered && !isMobile) ? dateRangeString : "Date"}</button>
                                {activeDatePicker &&
                                    <DateRangeMenu
                                        startDate={dateRange[0]}
                                        endDate={dateRange[1]}
                                        onChange={(dates) => setDateRange(dates)}
                                        onClose={() => setActiveDatePicker(false)}
                                    />
                                }
                            </div>
                            {(performer !== null && performer.venue !== undefined) &&
                                <>
                                    <div className='gap-s'/>
                                    <div className='relative' ref={homeAwayPickerRef}>
                                        {performer.category.path.split('/')[1] == "sports" &&
                                            <button className={`filter-button ${homeAwayFiltered && 'filtered'}`}
                                                    onClick={(() => setActiveHomeAwayPicker(!activeHomeAwayPicker))}>{homeAwayFiltered ? selectedHomeAway : "Home / Away"}</button>}
                                        {activeHomeAwayPicker &&
                                            <SingleSelectMenu
                                                options={['Home Games', 'Away Games']}
                                                selectedOption={selectedHomeAway}
                                                setSelectedOption={setSelectedHomeAway}
                                                closeMenu={() => setActiveHomeAwayPicker(false)}
                                                mustSelect={false}
                                                rightAligned={!isMobile}
                                            />
                                        }
                                    </div>
                                </>
                            }
                            <div className='gap-s'/>
                            <div className='relative' ref={cityPickerRef}>
                                <button className={`filter-button ${citiesFiltered && 'filtered'}`}
                                        onClick={(() => setActiveCityPicker(!activeCityPicker))}>City
                                </button>
                                {activeCityPicker &&
                                    <MultiSelectMenu
                                        options={Array.from(citiesSet)}
                                        selectedOptions={selectedCities}
                                        setSelectedOptions={setSelectedCities}
                                        onClose={() => setActiveCityPicker(false)}
                                    />
                                }
                            </div>
                        </div>
                    }

                    {(dateFiltered || homeAwayFiltered || citiesFiltered) &&
                        <>
                            <br/>
                            <div className='gap-s'/>
                            <button className='filter-button'
                                    style={isMobile ? {width: '100%', margin: '0px 20px'} : {}}
                                    onClick={handleResetFilters}>
                                {isMobile ?
                                    <>Clear Filters</>
                                    :
                                    <div className='col'>
                                        <FaTimes/>
                                    </div>
                                }

                            </button>
                        </>}
                    <br/>
                </div>
                {loadingEvents ? (
                    <div className="loading-spinner"></div>
                ) : (
                    <div className='col' >

                        {eventsToShow.length > 0 ? (
                            <>
                                {eventsToShow.map(event => (
                                    <React.Fragment key={event.tevoEventId}>
                                        <EventBar event={event} isMobile={isMobile} />
                                        {isMobile && <div className='divider-margin' />}
                                    </React.Fragment>
                                ))}
                                {loadingMoreEvents ? (
                                    <div>
                                        <br/>
                                        <br/>
                                        <div className='loading-spinner'/>
                                    </div>
                                ) : (
                                    !noMoreEvents ? (
                                        <div>
                                            <br/>
                                            <br/>
                                            <button className='filter-button no-margin' ref={buttonRef}>
                                                Show More
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="text-xs-success">
                                            <br/>
                                            <br/>
                                            &#x2714; No More Events Found
                                        </div>
                                    )
                                )}
                            </>
                        ) : (
                            <>
                                <br/>
                                <br/>
                                <div className='text-s'>No Upcoming Events</div>
                                <br />
                            </>
                        )}
                        <br />
                    </div>
                )}
            </div>
        </div>
    )
}

export default PerformerEvents;
