import React from 'react';
import {Link} from 'react-router-dom';
import Event from '../../interfaces/Event';
import './Events.css';
import {CalendarWidgetSmall, formatTimeZonedDateToWeekDayAndTime} from '../../utils/DateUtils/DateUtils'
import 'react-datepicker/dist/react-datepicker.css';
import {getEventUrlParams} from '../../utils/GlobalUtils';


interface EventBarProps {
    event: Event;
    isMobile: boolean;
}

const EventBar: React.FC<EventBarProps> = ({ event, isMobile }) => {

    return (
        <Link
            to={`/event/${getEventUrlParams(event)}`}
            className={`${isMobile ? "col event-bar" : "row event-bar"} `}
        >
            <div className={`${isMobile ? "row" : "left"} `}>
                <CalendarWidgetSmall dateTime={event.occursAtLocal} />
                <div className='col align-start'>
                    <div className='item-title'>{event.name}</div>
                    <div className={`${isMobile ? "text-xxs" : "text-xs-light"} `} >{event.venue.location + ' - ' + event.venue.name + ' - ' + formatTimeZonedDateToWeekDayAndTime(event.occursAtLocal)}</div>
                </div>
            </div>
            <div className='gap'></div>
            <br />
            <button className='action-button' style={isMobile ? { width: '100%', margin: '0px 20px' } : {}} >Find Tickets</button>
        </Link>
    )
};

export default EventBar;
