import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {fetchAccessToken} from '../api/endpoints';
import UserDto from '../interfaces/UserDto';
import LoginResponse from '../interfaces/LoginResponse';
import {isRight} from "fp-ts/Either";


interface AuthenticationContextType {
    accessToken: string | null;
    expiry: number | null;
    user: UserDto | null;
    isLoggedIn: boolean;
    isAuthLoading: boolean;
    setLogin: (loginResponse: LoginResponse | null) => void;
}


const AuthenticationContext = createContext<AuthenticationContextType>({
    accessToken: null,
    expiry: null,
    user: null,
    isLoggedIn: false,
    isAuthLoading: true,
    setLogin: () => {
    },
});

export const useAuthenticationContext = () => useContext(AuthenticationContext);

interface AuthenticationProviderProps {
    children: React.ReactNode;
}

export const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({children}) => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isAuthLoading, setIsAuthLoading] = useState(true);
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [expiry, setExpiry] = useState<number | null>(null);
    const [user, setUser] = useState<UserDto | null>(null);


    const updateToken = useCallback(async () => {
        setIsAuthLoading(true);
        try {
            const loginResponseEither = await fetchAccessToken();
            if (isRight(loginResponseEither)) {
                const loginResponse = loginResponseEither.right;
                setAccessToken(loginResponse.token);
                setExpiry(loginResponse.expiry);
                setUser(loginResponse.user);
                setLoginFromTokenAndExpiry(loginResponse.token, loginResponse.expiry)
            } else {
                console.error(loginResponseEither.left)
            }

        } catch (error) {
            console.error('Failed to fetch access token', error);
            setIsLoggedIn(false);
        } finally {
            setIsAuthLoading(false);
        }
    }, []);


    useEffect(() => {
        updateToken();

        if (expiry != null) {
            const interval = setInterval(() => {
                updateToken();
            }, expiry - Date.now());

            return () => clearInterval(interval);
        }

    }, [updateToken]);

    const setLoginFromTokenAndExpiry = (accessToken: string | null, expiry: number | null) => {
        if (accessToken && expiry) {
            const now = new Date().getTime();
            setIsLoggedIn(expiry > now);
        } else {
            setIsLoggedIn(false);
        }
    }

    const setLogin = (login: LoginResponse | null) => {
        if (login == null) {
            setAccessToken(null);
            setExpiry(null);
            setUser(null);
            setLoginFromTokenAndExpiry(null, null)
        } else {
            setAccessToken(login.token);
            setExpiry(login.expiry);
            setUser(login.user);
            setLoginFromTokenAndExpiry(login.token, login.expiry)
        }
    }

    return (
        <AuthenticationContext.Provider value={{accessToken, expiry, user, isLoggedIn, isAuthLoading, setLogin}}>
            {children}
        </AuthenticationContext.Provider>
    );
};

export default AuthenticationProvider;
