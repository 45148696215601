import React, {createContext, useContext, useEffect, useState} from 'react';
import {fetchDropdownMenu, fetchSuggestionMenu, getEnabledShipping} from '../api/endpoints';
import DropdownMenu from '../interfaces/DropdownMenu';
import SuggestionCategory from '../interfaces/SuggestionCategory';
import ShippingPricing from '../interfaces/ShippingPricing';
import {isRight} from "fp-ts/Either";

interface GlobalContextType {
  dropdown: DropdownMenu[];
  setDropdown: (dropdown: DropdownMenu[]) => void;

  suggestions: SuggestionCategory[];
  setSuggestions: (suggestions: SuggestionCategory[]) => void;

  shippingMethods: ShippingPricing[];
  setShippingMethods: (shippingMethods: ShippingPricing[]) => void;
}

const GlobalContext = createContext<GlobalContextType>({
  dropdown: [],
  setDropdown: () => {},
  suggestions: [],
  setSuggestions: () => {},
  shippingMethods: [],
  setShippingMethods: () => {}
});

export const useGlobalContext = () => useContext(GlobalContext);

interface GlobalProviderProps {
    children: React.ReactNode;
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
    const [dropdown, setDropdown] = useState<DropdownMenu[]>([]);
    const [suggestions, setSuggestions] = useState<SuggestionCategory[]>([]);
    const [shippingMethods, setShippingMethods] = useState<ShippingPricing[]>([]);

    useEffect(() => {
        const fetchInfoAndHandleResponse = async () => {
            const [
                dropdownResponseEither,
                suggestionResponseEither,
                shippingMethodResponseEither,
            ] = await Promise.all([
                fetchDropdownMenu(),
                fetchSuggestionMenu(),
                getEnabledShipping(),
            ]);

            if (isRight(dropdownResponseEither)) {
                setDropdown(dropdownResponseEither.right);
            } else {
                console.error(dropdownResponseEither.left)
            }
            if (isRight(suggestionResponseEither)) {
                setSuggestions(suggestionResponseEither.right);
            } else {
                console.error(suggestionResponseEither.left)
            }
            if (isRight(shippingMethodResponseEither)) {
                setShippingMethods(shippingMethodResponseEither.right);
            } else {
                console.error(shippingMethodResponseEither.left)
            }
        }

        fetchInfoAndHandleResponse();
    }, []);

    return (
        <GlobalContext.Provider value={{ dropdown, setDropdown, suggestions, setSuggestions, shippingMethods, setShippingMethods}}>
        {children}
        </GlobalContext.Provider>
    );
};

export default GlobalProvider;
