import React from 'react';
import Listing from '../../interfaces/Listing';
import ShippingPricing from '../../interfaces/ShippingPricing';
import {useScreenSizeContext} from "../../providers/ScreenSizeProvider";

interface OrderChargesCardProps {
    listing: Listing;
    selectedQuantity: number;
    couponAmount: number;
    rewardsAmount: number;
    shippingMethod: null | ShippingPricing
}

const OrderChargesCard: React.FC<OrderChargesCardProps> = ({ listing, selectedQuantity, couponAmount, rewardsAmount, shippingMethod }) => {
    const { isMobile } = useScreenSizeContext();
    const shippingAmount = shippingMethod ? shippingMethod.cost : 0;
    const subtotal = listing.price * selectedQuantity;
    const total = subtotal - couponAmount - rewardsAmount + shippingAmount;

    return (
        <div className={isMobile ? '' : 'card'}>
            <div className='card-item'>
                <div className='row'>
                    <div className='left'>
                        <div className='text-xs'>Subtotal</div>
                    </div>
                    <div className='text-xs-light'>${subtotal.toFixed(2)}</div>
                </div>
                {shippingAmount > 0 &&
                    <div className='row'>
                        <div className='left'>
                            <div className='text-xs'>Shipping</div>
                        </div>
                        <div className='text-xs-light'>${shippingAmount.toFixed(2)}</div>
                    </div>
                }
                {couponAmount > 0 &&
                    <div className='row'>
                        <div className='left'>
                            <div className='text-xs-success'>Coupons</div>
                        </div>
                        <div className='text-xs-success'>- ${couponAmount.toFixed(2)}</div>
                    </div>
                }
                {rewardsAmount > 0 &&
                    <div className='row'>
                        <div className='left'>
                            <div className='text-xs-success'>Rewards</div>
                        </div>
                        <div className='text-xs-success'>- ${rewardsAmount.toFixed(2)}</div>
                    </div>
                }
                <div className='divider-margin'></div>
                <div className='row'>
                    <div className='left'>
                        <div className='text-s'>Total</div>
                    </div>
                    <div className='text-s'>${total.toFixed(2)}</div>
                </div>
            </div>
        </div>
    )
}

export default OrderChargesCard;
