import React, {useEffect, useState} from 'react';
import {logOut, tryLogIn} from "../../api/endpoints";
import {Link} from "react-router-dom";
import {useAuthenticationContext} from "../../providers/AuthenticationProvider";
import {isRight} from 'fp-ts/lib/Either';
import {FaEye, FaEyeSlash} from 'react-icons/fa';


export const LoginMenu: React.FC = () => {
    const {setLogin} = useAuthenticationContext();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isRememberMe, setIsRememberMe] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);


    const [error, setError] = useState(false);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };


    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };


    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (email == '' || password == '') {
            setError(true);
            return
        }


        setIsLoading(true);
        const loginResponseEither = await tryLogIn(email, password, isRememberMe);
        if (isRight(loginResponseEither)) {
            const loginResponse = loginResponseEither.right;
            setLogin(loginResponse)
            setError(false);
        } else {
            console.error(loginResponseEither.left);
            setError(true);
        }
        setIsLoading(false);
    }


    return (
        <form className="dropdown-menu right-aligned pad" onSubmit={handleLogin}>
            <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Email"
                className="dropdown-input"
                required
            />
            <div className='row relative'>
                <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder="Password"
                    className="dropdown-input"
                    required
                />
                <div className='absolute hide-password' onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ?
                        <FaEyeSlash/>
                        :
                        <FaEye/>
                    }
                </div>
            </div>
            <div className='row'>
                <div className='left'>
                    <input
                        type="checkbox"
                        checked={isRememberMe}
                        onChange={() => setIsRememberMe(!isRememberMe)}
                        className='checkbox'
                    >
                    </input>
                    <div className='text-xs'>
                        Remember Me
                    </div>
                </div>
                <Link to="/forgot-password" className='text-xs-link'>
                    Forgot Password?
                </Link>
            </div>
            <button type="submit" className="dropdown-action-button">{isLoading ? "Loading" : "Log In"}</button>

            {error &&
                <>
                    <div className='card-list'>
                        <div className={`text-xs-error`}>
                            Email Address and or Password not associated with the new 714Tickets.com account. If this is your first time using the NEW website, please click &quot;sign up&quot; to create an account with the same email and password to access your rewards and account.
                        </div>
                    </div>
                    <br />
                </>
            }

            <div className='text-xs'>
                {"Don't have an account? "}
                <Link className="text-xs-link" to="/signup">
                    Sign Up
                </Link>
            </div>
        </form>
    );
};

export const LoggedInMenu: React.FC = () => {

    const { user, setLogin } = useAuthenticationContext();

    const handleLogOut = async () => {
        const loginResponseEither = await logOut();
        if (isRight(loginResponseEither)) {
            setLogin(null);
        } else {
            console.error(loginResponseEither.left);
        }
    };

    return (
        <div className='dropdown-menu right-aligned' style={{width: "250px", padding: "15px 20px"}}>
            <div className='card-item'>
                <div className='text-s'>Welcome {user?.firstName}!</div>
            </div>
            <Link className="dropdown-action-button" to="/account">
                Profile
            </Link>
            <button className="dropdown-logout-button" onClick={handleLogOut}>Log Out</button>
        </div>
    );
};
